@import "../../theme/iconMixin";

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
}

.salons {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.salonSearch {
	width: 100%;
	height: 64px;
	background-color: #efefef;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
}

.salonContainer {
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
	flex-grow: 1;
	font-size: 18px;
	width: 50%;
}

.salonName {
	font-weight: 500;
	letter-spacing: 0.45px;
}

.salonText {
	letter-spacing: 0.45px;
	padding-top: 5px;
}

.form {
	display: flex;
	align-items: center;
}

.salonTextLink {
	color: #0080ff;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.35px;
	margin-right: 10px;
}

.salonTextLinkContainer {
	padding-top: 7px;
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap-reverse;
}

.salonTextLinkFix {
	margin-right: 20px;
}

.salonOptions {
	display: flex;
	align-items: center;
}

.salonOption {
	width: 32px;
	height: 32px;
	margin-right: 12px;
	display: none;

	&:last-child {
		margin-right: 0;
	}

	&[data-icon="kids"] {
		@include icon('../../assets/icon-option-kids.svg');
	}

	&[data-icon="coffee"] {
		@include icon('../../assets/icon-option-coffee.svg');
	}

	&[data-icon="ramp"] {
		@include icon('../../assets/icon-option-ramp.svg');
	}

	&[data-icon="parking"] {
		@include icon('../../assets/icon-option-parking.svg');
	}

	&[data-icon="wifi"] {
		@include icon('../../assets/icon-option-wifi.svg');
	}

	&[data-icon="brows"] {
		@include icon('../../assets/icon-option-brows.svg');
	}
}

.salonOptionActive {
	display: inline-block;
}

.salonPhone, .salonBusinessHours {
	white-space: nowrap;
}

.salonForm {
	width: 332px;
	height: 41px;
	border: 1px solid #424243;
	background-color: #ffffff;
	margin-left: 15px;
	padding: 12px 20px;
	box-sizing: border-box;
	font-size: 18px;
	outline: none;
}

.salonFormBtn {
	width: 117px;
	height: 41px;
	border: 1px solid #424243;
	border-left: 0px;
	background-color: #fcedba;
	text-transform: uppercase;
	color: #424243;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.75px;
}

.salonFormBtnText {
	color: #424243;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.75px;
}

.paginator {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 100%;
}

.paginatorContainer {
	display: flex;
	align-items: center;
	font-size: 16px;
}

.paginatorText {
	color: #807f7f;
	font-weight: 500;
	box-sizing: border-box;
	letter-spacing: 1.2px;
	margin-right: 10px;
	margin-left: 10px;
	text-transform: uppercase;
	width: 20px;
	display: block;
	text-align: center;
	cursor: pointer;
}

.paginatorActivePage {
	color: #272727;
	font-weight: 500;
	box-sizing: border-box;
	letter-spacing: 1.2px;
	margin-right: 10px;
	margin-left: 10px;
	text-transform: uppercase;
	width: 20px;
	display: block;
	text-align: center;
	cursor: pointer;
}

.paginatorContainer::after {
	content: '|';
	display: block;
	font-weight: 400;
	letter-spacing: 1.19px;
}

.paginatorContainer:last-of-type::after {
	content: '';
}

@media screen and (max-width: 480px) {
	.salonContainer {
		margin: 15px 15px 0px 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #dadada;
		font-size: 14px;
		width: 100%;
	}

	.salonName {
		margin-bottom: 4px;
	}

	.salonAddress {
		margin-bottom: 4px;
	}

	.salonContacts {
		margin-bottom: 16px;
	}

	.salonOptions {
		margin-bottom: 16px;
	}

	.salonTextLinkContainer {
		flex-direction: column-reverse;
		align-items: flex-start;
		padding-top: 0;
	}

	.paginatorContainer {
		font-size: 14px;
	}


	.form {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 0 12px;
		box-sizing: border-box;
	}

	.salonForm {
		height: 30px;
		width: 50%;
		flex: 1 1 0;
		padding: 0 8px;
		margin-left: 0;
	}

	.salonFormBtn {
		height: 30px;
		width: auto;
		margin-right: 0;
		padding: 0 8px;
	}
}

