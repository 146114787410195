@import "./iconMixin";

@mixin circleBorderContainer($circleWidth, $circleHeight, $circleRotate) {

	$circleBorderWidth: 1px;

	width: $circleHeight;
	height: $circleHeight;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	z-index: 2;

	&:before {
		content: '';
		position: absolute;
		width: $circleWidth;
		height: $circleHeight;
		transform: rotate($circleRotate);
		left: $circleHeight - $circleWidth;
		top: $circleBorderWidth;
		transform-origin: calc(50% + #{($circleWidth - $circleHeight) / 2}) calc(50% + 0px);
		z-index: 3;
		@include icon('../../assets/avatar-border.svg');
	}
}

@mixin circleBorderContent($circleHeight, $contentOffsetX, $contentOffsetY) {
	width: $circleHeight - 2;
	height: $circleHeight - 2;
	border-radius: 50%;
	transform: translate3d($contentOffsetX, $contentOffsetY, 0)
}
