@import "../../theme/iconMixin";

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	flex: 0 0 14px;
	user-select: none;

	&.isBig {
		width: 19px;
		height: 60px;
		flex: 0 0 20px;

		.arrow {
			@include icon('../../assets/arrow-big.svg');
		}
	}
}

.arrow {
	width: 100%;
	height: 100%;
	@include icon('../../assets/arrow.svg');
}

.container[data-direction="right"] .arrow {

}

.container[data-direction="left"] .arrow {
	transform: rotate(180deg);
}

.container[data-direction="down"] .arrow {
	transform: rotate(90deg);

}

.container[data-direction="up"] .arrow {
	transform: rotate(-90deg);
}

@media screen and (max-width: 480px) {
	.arrow {
		@include icon('../../assets/arrow-thin.svg')
	}

	.container {
		z-index: 110;
	}
}
