.container {
	width: 100%;
	flex: 1 0 auto;
}

.yandexMapContainer {
	width: 100%;
	display: flex;
	justify-content: center;
}

.yandexHeader {
	position: relative;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	&.withHeader {
		height: 72px;
		box-sizing: border-box;

		.modePicker {
			margin-top: 12px;
		}
	}
}

.cityTitleContainer {
	display: flex;
	flex: 1;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	text-align: center;
	width: 100%;
	justify-content: center;
}

.cityTitle {
	font-size: 33px;
	line-height: 33px;
	font-weight: 500;
	letter-spacing: 0.025em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@media screen and (max-width: 480px) {

	.cityTitle {
		font-size: 24px;
		line-height: 30px;
		max-width: 100%;
		padding: 0 20px;
		display: block;
	}

	.yandexHeader {
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		&.withHeader {
			height: 92px;

			.modePicker {
				margin: 0;
			}
		}
	}
	.cityTitleContainer {
		position: relative;
		transform: none;
		left: auto;
		top: auto;
		margin-bottom: 16px;
		display: block;
		overflow: hidden;
		flex: 1 1;
	}
}
