.container {
	position: relative;
	overflow: hidden;
	text-align: center;
}

.tabs {
	display: inline-flex;
	justify-content: center;
	position: relative;
	transition: transform 200ms linear 0ms;
	will-change: transform;
}

.indicatorContainer {
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	overflow: hidden;
	position: relative;
	flex-shrink: 0;
}

.indicator {
	width: 2800px;
	height: 40px;
	position: absolute;
	transition: transform 200ms linear 0ms;
	will-change: transform;
	background-image: url('../../assets/tabs-indicator.svg');
	background-position: center center;
	background-repeat: no-repeat;
}
