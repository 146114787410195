@import "../../theme/colors";
@import "../../theme/borders";
@import "../../theme/iconMixin";

.container {
	padding: 24px 0;

	&:last-of-type {
		border-bottom: none;
	}

	&.short {
		border-bottom: none;
		padding: 12px 0;
		margin-bottom: 0;

		&.selected .name:before {
			display: none;
		}
	}

	&.selected .name {
		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 16px;
			height: 16px;
			left: -24px;
			@include icon('../../assets/checkmark-small.svg');
		}
	}

}

.mainInfo {
	display: flex;
	align-items: flex-start;
}

.name {
	white-space: nowrap;
	align-items: center;
	font-weight: 500;
	margin-right: 12px;
	position: relative;
}

.title {
	white-space: normal;
	vertical-align: middle;
	font-size: 16px;
	line-height: 1.2em;
	margin-right: 6px;
}

.icon {
	vertical-align: middle;
	display: inline-block;
	height: 16px;

	& > img {
		height: 100%;
		width: auto;
	}
}

.durationContainer {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.durationIcon {
	display: block;
	width: 21px;
	height: 21px;
	@include icon('../../assets/icon-clock-small.svg');
	margin-right: 6px;
}

.duration {
	white-space: nowrap;
	font-size: 18px;
	font-weight: normal;
}

.attributes {
	display: inline-flex;
	margin-left: auto;
}

.priceBg {
	background-color: $color-yellow;
	display: inline-block;
	padding: 0 2px 0 4px;
	white-space: nowrap;
}

.priceContainer {
	width: 80px;
	text-align: right;
	margin-left: 6px;
	flex-shrink: 0;
	font-size: 18px;

	.price {
		font-weight: 500;
	}

	.currency {
		font-weight: 200;

		&:before {
			content: ' ';
		}
	}
}

.currency {
	font-weight: 200;

	&:before {
		content: ' ';
	}
}

.additionalInfo {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

.description {
	font-size: 14px;
	line-height: 1.2em;
}

.buttonContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 12px;
}

.button {
	width: 150px;
}

.amountContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	margin: 8px 0;

	.amount {
		width: 32px;
		height: 24px;
		line-height: 24px;
		box-sizing: border-box;
		text-align: center;
		border: $dark-border-thin;
		user-select: none;
	}

	.amountIncrease {
		margin-left: 8px;

		&:before {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			@include icon('../../assets/icon-add.svg');
		}
	}

	.amountReduce {
		margin-right: 8px;

		&:before {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			@include icon('../../assets/icon-delete.svg');
		}
	}
}

@media screen and (max-width: 480px) {
	.container {
		padding: 0;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: $light-border-thin;

		&.short {
			padding: 8px 0;
		}

		&.selected .name {
			&:before {
				width: 12px;
				height: 12px;
				left: -16px;
			}
		}

		.title {
			font-size: 14px;
		}

		.duration {
			font-size: 10px;
		}

		.priceContainer {
			font-size: 14px;
		}

		.attributes {
			flex-direction: column-reverse;
		}

		.description {
			font-size: 12px;
		}

		.button {
			width: 218px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
