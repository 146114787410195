@import "./theme/colors";

html, body {
	width: 100%;
	min-height: 100%;
}

.grecaptcha-badge {
	visibility: hidden;
}

body {
	font-family: 'Rubik', sans-serif;
	color: $color-dark-grey;
}

.App {
	margin: 0;
	padding: 0;
	background-color: #eaeadb;
	min-height: 100vh;
	min-height: 100dvh;
	box-sizing: border-box;
}

.init-error {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	height: 100vh;
	height: 100dvh;
}

.page-content {
	padding: 0 30px;
	width: 100%;
	box-sizing: border-box;
}

.page-content.loading {
	height: 400px;
}

.page-not-found {
	text-align: center;
	font-size: 32px;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	width: 100%;
	padding: 40px 0;
}

.empty-image {
	background-color: $color-light-grey;
	width: 100%;
	height: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	margin: auto;
	min-width: 1024px;
	max-width: 1200px;
	min-height: 100vh;
	min-height: 100dvh;
	background-color: white;
	position: relative;
}

.text-content {

	line-height: 1.2em;

	ul {
		padding-left: 20px;
		list-style: disc;

		&:not(:last-child) {
			margin-bottom: 8px;
		}

		li {
			margin-bottom: 4px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	ol {
		list-style: decimal;
		padding-left: 20px;

		&:not(:last-child) {
			margin-bottom: 8px;
		}

		li {
			margin-bottom: 8px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	strong, b {
		font-weight: 500;
	}

	p {
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}
	}

}

ul, li, body, html, p, h1, h2, h3 {
	margin: 0;
	padding: 0;
}

button, input, textarea {
	font-family: inherit;
	padding: 0;
	margin: 0;
}

input, button, textarea {
	border-radius: 0;
	background: inherit;
}

a {
	color: inherit;
}

iframe {
	border: none;
}

.ReactModal__Body--open {
	overflow: hidden;
}

a[href^=tel],
a[href^=mailto] {
	text-decoration: none;
}

@media screen and (max-width: 480px) {
	.container {
		min-width: 310px;
		max-width: 480px;
	}
}


@media screen and (max-width: 480px) {

	.page-content {
		padding: 0;
	}

}
