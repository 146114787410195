@import "../../theme/colors";

.container {
	width: 400px;
}

.content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	box-sizing: border-box;
	padding: 20px 32px;
	font-weight: 200;

	&[data-status='success'] .icon {
		background-image: url('../../assets/icon-success.svg');
	}

	&[data-status='error'] .icon {
		background-image: url('../../assets/icon-error.svg');
	}
}

.icon {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	width: 72px;
	height: 72px;
	margin-bottom: 24px;
}

.text {
	line-height: 1.2em;
	text-align: center;
	font-size: 20px;
	margin-bottom: 24px;
	font-weight: 200;
}
