@import "../../theme/borders";

.categories {
	padding: 16px 0;
}

.actions {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 32px 0;
	border-top: $light-border;
}
