@import "~@/theme/colors";

.container {
	display: flex;
	align-items: center;
	margin-right: 8px;
	text-transform: uppercase;

	&:after {
		content: '|';
		margin-left: 8px;
		display: block;
		font-size: 18px;
		letter-spacing: 1.19px;
	}

	&:last-of-type {
		margin-right: 0;

		&:after {
			display: none;
		}
	}

	&[data-position="header"] {

	}

	&[data-position="footer"] {

	}

	&[data-position="extra"] {

	}
}

.text {
	text-transform: uppercase;
	letter-spacing: 1.19px;
	cursor: pointer;
}

.link {
	text-decoration: none;
	overflow: hidden;
}

@media screen and (max-width: 480px) {

	.container {
		border-bottom: 2px solid $color-blue;
		margin: 0;
		padding: 8px;

		&:first-of-type {
			border-top: 2px solid $color-blue;
		}

		&:after {
			display: none;
		}

		&[data-position="header"] {

		}

		&[data-position="footer"] {
			justify-content: center;
		}

		&[data-position="extra"] {

			border: 0;
			padding: 8px 8px 0 8px;

			&:first-of-type {
				border-top: 0;
			}

			&:last-of-type {
				padding: 8px 8px 15px 8px;
			}
		}
	}

}
