@import "../../theme/iconMixin";

.star {
	display: block;
	width: 31px;
	height: 31px;
	margin: 0 6px;
	@include icon('../../assets/star.svg');

	&.active {
		@include icon('../../assets/star-filled.svg');
	}
}

@media screen and (max-width: 480px) {
	.star {
		width: 40px;
		height: 40px;
	}
}
