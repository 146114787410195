@import "~@/theme/iconMixin";

.resend {
	font-size: 14px;
}

.agreementLink {
	display: inline-block;
	vertical-align: middle;
	margin-left: 4px;
	width: 13px;
	height: 13px;
	@include icon('~@/assets/icon-info-circle.svg');
}
