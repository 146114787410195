.container {

	&[data-position="header"] {

	}

	&[data-position="footer"] {

		.content {
			display: flex;
			justify-content: center;
			font-size: 14px;
		}

		.menu {
			list-style-type: none;
			display: flex;
			padding: 0 12px;
		}
	}

	&[data-position="extra"] {

	}

}

.menu {
	list-style-type: none;
	display: flex;
	padding: 0 12px;
}

.content {
	display: flex;
	align-items: center;
	font-size: 18px;
	min-width: 1024px;
	padding: 0 30px;
	box-sizing: border-box;
}

.border {
	flex: 1;
	border: 1px solid #cedde4
}

@media screen and (max-width: 480px) {

	.content {
		display: block;
		padding: 0;
		width: 100%;
		min-width: 310px;
		max-width: 480px;
	}

	.border {
		display: none;
	}

	.container {
		display: flex;
		font-size: 18px;
		max-width: 480px;
		padding: 5px;
		box-sizing: border-box;
		min-width: 310px;

		&[data-position="header"] {

		}

		&[data-position="footer"] {
			.menu {
				width: 100vw;
				flex-direction: column;
			}
		}

		&[data-position="extra"] {

			display: flex;
			max-width: 480px;
			padding: 0 5px;
			box-sizing: border-box;
			min-width: 310px;

			.content {
				width: 100%;
				min-width: 310px;
				max-width: 480px;
				font-size: 14px;
			}

			.menu {
				list-style-type: none;
				display: flex;
				padding: 0 12px;
				flex-direction: column;
			}
		}
	}

	.menu {
		flex-direction: column;
	}

}


