@import "../../theme/colors";

.container {
	width: 100%;
	flex: 1 0 auto;
	box-sizing: border-box;
	//padding: 0 30px;
}

.yandexMapContainer {
	width: 100%;
	display: flex;
	justify-content: center;

}

.yandexHeader {
	padding-left: 30px;
	padding-right: 30px;
}


.sliderContainer {
	position: relative;
}

.arrows {
	position: absolute;
	width: 100%;
	top: 50%;
	transform: translate(0, -50%);
	z-index: 12;
	pointer-events: none;
	user-select: none;

	.arrowLeft {
		left: -28px;
	}

	.arrowRight {
		right: -28px;
	}

	.arrowLeft, .arrowRight {
		pointer-events: all;
		position: absolute;
		cursor: pointer;
		top: 50%;
		transform: translate(0, -50%);
	}
}

.slider {
	width: 100%;
	display: flex;
	box-sizing: border-box;
	flex: 0;
	overflow: hidden;
	position: relative;
}


.slide {
	width: 100%;
	display: flex;

	.image {
		position: relative;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		max-height: 480px;

		a {
			width: 100%;
		}

		img {
			width: 100%;
			object-fit: cover;
		}
	}

	.imageInactive img {
		display: none;
	}

	.info {
		width: 400px;
		flex: 0 0 400px;
		display: flex;
		flex-direction: column;
	}
}

.servicesList {
	border-bottom: 2px solid $color-blue;
	padding: 35px 0;
}

.slideTitleContainer {
	margin-bottom: 8px;
}

.slideTitle {
	font-weight: 500;
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	padding: 0 6px;
	width: auto;
	margin-left: -6px;
	margin-right: 6px;
	font-size: 24px;
	background-color: $color-satin;
	display: inline;
}

.slideDescription {

}

@media screen and (max-width: 480px) {

	.sliderContainer {
	}

	.slide {
		flex-direction: column;

		.image {
			height: auto;
			margin-right: 0;
			max-width: 100vw;

			img {
				height: auto;
			}
		}

		.info {
			margin-top: 15px;
			align-items: center;
			padding: 0 20px;
			width: auto;
			flex: 1;
		}
	}

	.arrows {

		.arrowLeft {
			left: 0;
		}

		.arrowRight {
			right: 0;
		}

	}
}
