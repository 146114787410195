@import "../../theme/iconMixin";
@import "../../theme/borders";

.container {
	display: flex;
	flex-direction: column;
}

.addressContainer {
	display: flex;
	align-items: center;
	margin-bottom: 28px;
	line-height: 1.2em;

	.icon {
		width: 64px;
		height: 64px;
		@include icon('../../assets/icon-placemark-shadow.svg');
		margin-right: 8px;
	}

	.info {
		font-size: 18px;

		.name {
			font-weight: 500;
		}
	}
}

.option {
	list-style: disc;
	margin-left: 20px;
}

.props {
	margin-bottom: 32px;

	.title {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: .04em;
		margin-bottom: 8px;
	}

	.value {
		font-size: 18px;
		font-weight: 500;
		letter-spacing: .04em;

		li {
			margin-bottom: 4px;
		}
	}
}

.callButton {
	display: none;
}

@media screen and (max-width: 480px) {
	.options {
		display: none;
	}
	.props {
		margin-bottom: 24px;
		text-align: center;

		.title {
			width: 100%;
		}

		.value {
			width: 100%;
		}
	}
	.addressContainer {
		text-align: center;
		margin-bottom: 24px;
		width: 100%;

		.info {
			flex: 1;
		}

		.icon {
			display: none;
		}
	}
	.contacts {
		display: none;
	}
	.callButton {
		display: block;
	}
}
