.container {
	width: 100%;
}

.link {
	display: flex;
	align-items: center;
	padding: 0 4px;
	flex: 0 0 30px;
	height: 30px;
}

.arrow {
	margin-right: 4px;
	flex: 0 0 16px;
}

.text {
	text-transform: uppercase;
	font-size: 16px;
	line-height: 16px;
}

@media screen and (max-width: 480px) {
	.text {
		display: none;
	}
	.link {
		height: 21px;
		padding: 0;
	}
	.arrow {
		width: 21px;
		height: 21px;
		flex: 0 0 21px;
		margin-right: 0;
	}
}
