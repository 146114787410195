@import '../../theme/colors';
@import '../../theme/borders';
@import '../shadow';

$input-height: 50px;
$input-font-size: 18px;
$input-dense-height: 40px;
$input-dense-font-size: 16px;

.inputContainer {
	position: relative;
}

.error .input {
	border-color: $color-red;

	&:focus {
		box-shadow: 0 0 0 1px $color-red;
	}
}

.tooltip {
	position: absolute;
	left: 0;
	bottom: -1em;
}

.error .tooltip {
	color: $color-red;
}

.input {
	outline: none;
	text-align: center;
	position: relative;
	z-index: 1;
	border: $dark-border;
	color: inherit;
	width: 100%;
	box-sizing: border-box;
	background-color: transparent;
	font-size: $input-font-size;
	appearance: none;
	line-height: initial;
	height: $input-height;
	padding: 0 12px;
	transform: translateZ(0);
	font-family: inherit;

	.dense & {
		height: $input-dense-height;
		font-size: $input-dense-font-size;
		border: $dark-border-thin;
	}

	&::-webkit-input-placeholder {
		color: inherit;
		text-align: center;
	}

	&:-ms-input-placeholder {
		color: inherit;
		text-align: center;
	}

	&:-moz-placeholder {
		color: inherit;
		text-align: center;
	}

	&::-moz-placeholder {
		color: inherit;
		text-align: center;
	}

	&:focus {
		box-shadow: 0 0 0 1px $color-dark-grey;
	}

	&[disabled] {
		background-color: $color-blue;
	}
}

@media screen and (max-width: 480px) {
	.input {
		height: 40px;
		font-size: 14px;
		border-width: 1px;
	}
}
