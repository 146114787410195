.container {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	box-sizing: border-box;
	padding: 24px;
	z-index: 20000;
	background-color: #FFFFFF;
	line-height: 1.2em;
}

.constrains {
	width: 1200px;
	display: flex;
	align-items: center;
	margin: 0 auto;
	justify-content: space-between;
}

.text {
	margin-right: 24px;
	flex-shrink: 1;
}

.button {
	flex-shrink: 0;
}

@media screen and (max-width: 480px) {
	.constrains {
		flex-direction: column;
		width: 100%;
	}
	.text {
		margin-right: 0;
		margin-bottom: 16px;
		font-size: 14px;
	}
}
