@import "../../theme/colors";

.container {
	width: 100%;
	flex: 1 0 auto;
	box-sizing: border-box;
}

.contentContainer {
	border-bottom: 2px solid $color-blue;
}

.textContainer {
	display: flex;
}

.content {
	flex: 1;
	margin-bottom: 24px;
}

.title {
	font-size: 35px;
	font-weight: 500;
	margin-bottom: 24px;
	line-height: 1.2em;
}

.img {
	width: 100%;

	img {
		max-width: 100%;
	}
}

.imageContainer {
	margin: 30px;
	box-sizing: border-box;
	width: 320px;
}

.MobileBtns {
	margin-right: 30px;
}

.links {
	margin-bottom: 32px;
}

@media screen and (max-width: 480px) {

	.textContainer {
		flex-direction: column;
		padding: 0 20px;
		margin-bottom: 20px;
	}

	.title {
		font-size: 21px;
		width: auto;
	}

	.links {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.links a:first-of-type {
		margin-bottom: 25px;
	}

	.links button {
		margin-bottom: 16px;
	}

	.imageContainer {
		order: -1;
		display: flex;
		width: 100%;
		justify-content: center;
		margin: 0;
		margin-bottom: 30px;
		height: auto;
	}

	.img {
		margin: 0;
		width: 100%;
		height: auto;
	}

	.mobileMap {
		display: none;
	}
}
