@import "../../theme/borders";

$icon-width: 77px;
$icon-offset: 20px;

.container {
	position: relative;

	&:last-of-type:not(.withChildren) .headerText {
		border-bottom: none;
	}
}

.expanded {
	position: relative;

	.headerText {
		border-bottom: none;
	}

	.children {
		border-bottom: $light-border-thin;
	}
}

.collapsed {
	position: relative;
}

.withChildren {
	position: relative;
}

.header {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.headerText {
	flex: 1;
	display: flex;
	align-items: center;
	padding: 16px 0;
	border-bottom: $light-border-thin;
}

.image {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: $icon-offset;
	width: $icon-width;
	height: 44px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;

	flex: 0 0 77px;
}

.name {
	font-size: 24px;
	font-weight: 500;
	user-select: none;
	margin-right: 8px;
}

.button {
	flex: 1 1;
	text-overflow: ellipsis;
	margin-right: 6px;
}

.arrow {
	height: 24px;
	width: 24px;
	flex: 0 0 24px;
}

.children {
	margin-left: $icon-width + $icon-offset;
}

@media screen and (max-width: 480px) {
	.image {
		display: none;
	}

	.headerText {
		padding: 12px 0;
	}

	.name {
		font-size: 21px;
	}

	.arrow {
		width: 21px;
		height: 21px;
		flex: 0 0 21px;
	}

	.children {
		margin-left: 0;
	}
}
