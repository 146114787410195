@import "~@/theme/circleBorderMixin";
@import "~@/theme/colors.scss";
@import "~@/theme/borders.scss";

.root {
	position: relative;
	min-height: 240px;
}

.stars {
	height: 150px;
	background-image: url('~@/assets/stars-bg.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cashbackAmount {
}

.cashbackAmountContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1.5em;
	z-index: 2;
}

.cashbackAmountContent:before {
	content: '';
	display: block;
	width: 105px;
	height: 105px;
	border-radius: 50%;
	background-color: $color-yellow;
	position: absolute;
	top: 6px;
	left: 2px;
	z-index: -1;
}

.amountNumber {
	font-size: 28px;
	font-weight: 500;
}


.circleBorderContent {
	position: relative;
	border-radius: 50%;
	@include circleBorderContent(109px, 4px, 0px);
}

.circleBorderContainer {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	@include circleBorderContainer(116px, 109px, -30deg);
}


.currentLevelContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.currentLevelTitle {
	text-transform: uppercase;
	font-size: 33px;
	font-weight: 500;
	line-height: 1.5em;
}

.currentLevelValue {
	font-size: 18px;
}

.cashbackAmountContainer {
	border-bottom: $light-border;
	padding-bottom: 32px;
}

.progressContainer {

}

.progressPart {
	&:not(:last-of-type) {
		margin-bottom: 32px;
	}
}

.progressPartTitle {
	margin-bottom: 6px;
	font-weight: 500;
	font-size: 20px;
	text-transform: uppercase;
}

.progressLine {
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;
	justify-content: space-between;
	margin-bottom: 8px;
}

.progressContainer {
	padding: 20px 0;
	border-bottom: $light-border;
}

.progressInfo {
	font-size: 14px;
	font-weight: 200;
	margin-bottom: 6px;
}

.progressInfo:last-of-type {
	margin-bottom: 0;
}

.circle {
	box-sizing: border-box;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: $dark-border-thin;
	align-items: center;
	justify-content: center;
	display: flex;
	background-color: #fff;
	z-index: 2;

	&.active {
		background-color: $color-yellow;
	}
}


.decorationBorder {
	position: absolute;
	top: 50%;
	border-bottom: $dark-border-thin;
	transform: translateY(-50%);
	width: 100%;
}

.levelsDescr {
	padding: 20px 0;
}

.level {
	margin-bottom: 12px;
}

.levelDescr {
	margin-left: 32px;
	line-height: 1.5em;
}


.progressBackground {
	height: 22px;
	background-color: $color-yellow;
	position: absolute;
	left: 0;
	top: 1px;
	margin: 0 14px;
}

.progressPercent {
	font-size: 8px;
}

.checkmark {
	margin-right: 8px;

	&:before {
		content: '';
		display: block;
		width: 16px;
		height: 16px;
		background-image: url('~@/assets/checkmark-small.svg');
		background-repeat: no-repeat;
	}
}

.levelTitle {
	display: flex;
	align-items: center;
	font-size: 20px;
}

.levelName {
	font-weight: 500;
	margin-right: 4px;
}

.levelPercent {

}
