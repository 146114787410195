@import "../../theme/colors";
@import "../../theme/borders";
@import "../../theme/iconMixin";

.container {
	position: relative;
	margin-bottom: 16px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.container:last-child {
	border-bottom: none;
}

.expandButton {
	position: absolute;
	background-color: #fff;
	top: 8px;
	right: 0;
	z-index: 2;
}

.header {
	display: flex;
	position: relative;
	cursor: pointer;
	align-items: center;
}

.headerInfo {
	margin-left: 16px;
	font-size: 18px;
}

.dateInfo {
	width: 100%;
	font-weight: 500;
}

.dateInfoIcon {
	@include icon('../../assets/icon-person-satin.svg');
	width: 40px;
	height: 40px;
}

.salonInfo {
	//font-size: 18px;
}

.expandButton {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 14px;
	flex: 0 0 14px;
}

.salonInfoIcon {
	@include icon('../../assets/icon-placemark-satin.svg');
	width: 40px;
	height: 40px;
}

.ratingIcon {
	@include icon('../../assets/icon-star.svg');
	width: 40px;
	height: 26px;

	&.hasRating {
		@include icon('../../assets/icon-star-filled.svg');
		opacity: 1;
	}

	&.disabled {
		opacity: .5;
	}
}

.moreInfo {
	padding-left: 40px;
	margin-left: 16px;
}

.summary {
	border-bottom: $light-border-thin;
}

.clientSummary {
	padding: 0;
}

.actions {
	padding: 12px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.action {
	cursor: pointer;
	margin-right: 16px;
	display: flex;
	align-items: center;
	font-size: 16px;

	&:last-of-type {
		margin-bottom: 0;
	}

	&[data-name="change"] .actionIcon {
		@include icon('../../assets/icon-change.svg');
	}

	&[data-name="route"] .actionIcon {
		@include icon('../../assets/icon-route.svg');
	}

	&[data-name="call"] .actionIcon {
		@include icon('../../assets/icon-phone-small.svg');
	}

	&[data-name="cancel"] .actionIcon {
		@include icon('../../assets/icon-cancel.svg');
	}

	&[data-name="repeat"] .actionIcon {
		@include icon('../../assets/icon-repeat.svg');
	}
}

.actionIcon {
	width: 27px;
	height: 27px;
	margin-right: 8px;
}

@media screen and (max-width: 480px) {
	.headerInfo {
		margin-left: 10px;
		font-size: 14px;
	}

	.moreInfo {
		margin: 0;
		padding: 0;
	}

	.actions {
		flex-direction: column;
		align-items: flex-start;

		.action {
			margin-bottom: 8px;
			font-size: 14px;

			.actionIcon {
				width: 24px;
				height: 24px;
			}
		}
	}
}
