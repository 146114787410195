.account {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.accountText {
	font-size: 14px;
	line-height: 1em;
	font-weight: 500;
	margin-right: 4px;
}

.avatar {
	width: 25px;
	height: 25px;
	margin-right: 8px;
}

.previewContainer {
	position: relative;
}

@media screen and (max-width: 480px) {

	.accountText {
		display: none;
	}

	.previewArrow {
		display: none;
	}

	.avatar {
		width: 24px;
		height: 24px;
		margin-right: 0;
	}
}

