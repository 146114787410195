@import "../../theme/colors";
@import "../../theme/borders";
@import "../../theme/iconMixin";

.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	box-sizing: border-box;
}

.avatarHeader {
	flex-shrink: 0;
	position: sticky;
	top: 0;
	z-index: 12;
}

.allVisitsContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 0;
	margin-bottom: 16px;
	min-height: 240px;
}

.noVisits {
	flex: 1 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;
	line-height: 1.2em;
}

.visitsContainer {
	border-radius: 18px;
	background: white;
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 6px 0;
}

.delimiter {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	white-space: nowrap;
	font-size: 24px;
	font-weight: 500;
}

.pages {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 100%;
	flex-wrap: wrap;
	margin-top: auto;
}

.page {
	cursor: pointer;
	display: flex;
	color: $color-medium-grey;
	font-size: 14px;
	align-items: center;

	&:after {
		content: '|';
		display: block;
		font-weight: 400;
	}

	&:last-of-type::after {
		content: '';
	}
}

.pageLink {
	display: block;
	width: 20px;
	margin: 0 8px;
	text-align: center;
	text-decoration: none;

	&.active {
		color: $color-dark-grey;
		font-weight: 500;
	}
}

@media screen and (max-width: 480px) {
	.delimiter {
		font-size: 21px;
		height: auto;
		margin-bottom: 24px;
	}
	.visitsContainer {
		margin-bottom: 32px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
