.container {
	width: 480px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	z-index: 101;
	line-height: 1.2em;
	padding: 16px;
	box-sizing: border-box;
	cursor: default;
}


.nameContainer {
	margin-bottom: 16px;
}

.footerTextContainer {

}

.ulStyle {
	margin-top: 0;
	columns: 100px 3;
	list-style: none;
	margin-bottom: 16px;
}

.ulSpanStyle {
	font-size: 14px;
	letter-spacing: -0.65px;
	text-decoration: none;
}

.ulSpanStyleActiveCity {
	text-decoration: none;
	color: #ef4136;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: -0.65px;
}

.ulStyle li::before {
	content: '';
	width: 3px;
	height: 3px;
	background-color: #414042;
	border-radius: 50%;
	vertical-align: middle;
	margin-right: 3px;
	display: inline-block;
	flex-shrink: 0;

}

.ulStyle li {
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	display: flex;
	align-items: center;
}


.ulSpanStyle:hover {
	color: #ef4136;
}


.footerText {
	font-size: 14px;
	font-weight: 200;
	letter-spacing: 0.35px;
	font-style: italic;
}

.headerText {
	color: #414042;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.45px;
}

.CityMobile {
	display: none;
}

@media screen and (max-width: 480px) {

	.container {
		width: 100%;
	}

	.nameContainer {
		display: none;
	}

	.ulStyle {
		columns: unset;
	}

	.ulSpanStyle {
		padding: 8px;
		max-width: 480px;
		min-width: 310px;
		width: 100vw;
		box-sizing: border-box;
	}

	.ulStyle li {
		border-bottom: 2px solid #cedde4;
	}

	.ulStyle li:first-child {
		border-top: 2px solid #cedde4;
	}

	.ulSpanStyleActiveCity {
		padding: 8px;
		max-width: 480px;
		min-width: 310px;
		width: 100vw;
		box-sizing: border-box;
	}

	.CityMobile {
		display: flex;
		padding: 15px 0 10px 0;
		pointer-events: none;
		cursor: default;
	}

	.footerText {
		font-size: 11px;
		line-height: normal;
	}
}
