@import "../../theme/borders";
@import "../../theme/colors";

.container {
	width: 100%;
	box-sizing: border-box;
}

.mainInfo {
	width: 100%;
	display: flex;
	box-sizing: border-box;
	//padding: 0 30px;
	margin-bottom: 32px;
	flex-wrap: wrap;
}

@supports (display: grid) {
	.mainInfo {
		display: grid;
		grid-template-areas: "a b" "a c";
		grid-auto-columns: minmax(auto, 750px) 380px;
		grid-template-rows: minmax(52px, 64px) auto;

		.titleContainer {
			grid-area: b;
		}

		.slider {
			grid-area: a;
		}

		.salon {
			grid-area: c;
		}
	}
}

.titleContainer {
	margin-top: 2px;
	margin-bottom: 16px;
	order: -1;
	width: 100%;
}

.title {
	font-size: 24px;
	font-weight: 500;
	white-space: normal;
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	padding: 0 6px;
	display: inline;
	width: auto;
	margin-left: -6px;
	background-color: $color-yellow;
	max-width: 380px;
}

.slider {
	flex: 1;
	margin-right: 24px;
	max-width: 750px;
}

.currentSlide {
	position: relative;
	width: 100%;
	height: 480px;

	.currentSlideImage {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.thumbsContainer {
	width: 100%;
	flex: 1 1 0;
	position: relative;
}

.salon {
	width: 380px;
	flex: 0 0 380px;
}

.emptyImage {
	width: 100%;
	height: 100%;
	background-color: $color-light-grey;
}

.thumbnails {
	display: flex;
	margin: 8px 0;
	flex-wrap: wrap;

	.thumb {
		position: relative;
		width: 102px;
		height: 78px;
		padding: 4px;
		box-sizing: border-box;
		flex: 0 0 102px;
		margin-right: 24px;
		cursor: pointer;
		border: 1px solid transparent;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		&.active {
			border: $dark-border-thin;
		}
	}

	.thumbImage {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.thumbsScroller {
	display: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;
	height: 78px;
	width: 24px;
	align-items: center;
}

.thumbsScroller[data-direction="left"] {
	left: 0;
	background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 100%);
	justify-content: flex-start;
}

.thumbsScroller[data-direction="right"] {
	right: 0;
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 1) 100%);
	justify-content: flex-end;
}

.thumbsScroller.hidden {
	display: none !important;
}

.additionalTitle {
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: .04em;
	margin-bottom: 8px;
}

.props {
	margin: 16px 0;

	.value {
		font-weight: 500;
		letter-spacing: .04em;

		li {
			margin-bottom: 4px;
		}
	}
}

.description {
	padding-bottom: 16px;
	margin-bottom: 16px;

	.value {
		font-weight: normal;
		line-height: 1.2em;
	}
}

.option {
	list-style: disc;
	margin-left: 20px;
}

@media screen and (min-width: 480px) {
	.thumbnails {
		transform: none !important;
	}
}

@supports (display: grid) {
	.thumbnails {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(102px, 1fr));
		grid-gap: 12px 24px;

		.thumb {
			width: auto;
			margin-right: 0 !important;
		}
	}
}

.options {
	display: none;
}

.additional {
	padding-top: 24px;
	border-top: 2px solid $color-blue;
}

@media screen and (max-width: 480px) {

	.options {
		display: block;
	}

	.mainInfo {
		max-width: 480px;
		min-width: 310px;
		width: 100vw;
		flex-direction: column;
		grid-auto-columns: minmax(auto, 100%) 380px;
	}

	.thumbnails {
		padding: 0 8px;
		display: inline-flex;
		flex-wrap: nowrap;
		flex-shrink: 0;

		.thumb {
			width: 102px;
			flex: 0 0 102px;
		}
	}

	.thumbsContainer {
		display: flex;
		overflow: hidden;
		box-sizing: border-box;
		width: 100%;

		.thumbsScroller {
			display: inherit;
		}
	}

	.salon {
		display: flex;
		justify-content: center;
		padding: 0 20px;
		width: auto;
		box-sizing: border-box;
		flex: 1;
	}

	.info {
		width: 100%;
	}

	.currentSlide {
		height: auto;
		min-height: 240px;

		iframe {
			height: auto;
			min-height: 240px;
		}
	}

	.slider {
		width: 100%;
		margin: 0 0 16px;
	}

	.titleContainer {
		box-sizing: border-box;
		text-align: center;
		padding: 0 20px;
	}

	.additional {
		font-size: 14px;
		padding: 24px 20px 0;
	}

	@supports (display: grid) {
		.mainInfo {
			grid-template-areas: "b" "a" "c";
			grid-template-rows: minmax(52px, 88px) auto;
		}
	}
}

