.mapContainer {
		width: 100%;
		height: 435px;
		position: relative;
}

.mapError {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
}
