@import "~@/theme/iconMixin";

.container {
	display: flex;
	flex-wrap: wrap;
}

.salonSearch {
	width: 1140px;
	height: 64px;
	background-color: #dadada;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
}

.salonContainer {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	font-family: 'Rubik', serif;
}

.salonName {
	color: #414042;
	font-weight: 500;
	letter-spacing: 0.45px;
}

.salonText {
	letter-spacing: 0.45px;
	padding-top: 5px;
	white-space: nowrap;
}

.salonTextLink {
	color: #0080ff;
	font-size: 14px;
	letter-spacing: 0.35px;
	margin-right: 10px;
	text-decoration: underline;
}

.salonTextLinkContainer {
	padding-top: 7px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.salonTextLinkFix {
	margin-right: 20px;
}

.SalonsImg {
	margin-right: 12px;
	transition: all 200ms linear 0ms;
}

.SalonsImg:hover {
	opacity: 0.6;
}

.salonForm {
	width: 332px;
	height: 41px;
	border: 1px solid #424243;
	background-color: #ffffff;
	margin-left: 15px;
	padding: 12px 20px;
	box-sizing: border-box;

}

.salonFormBtn {
	width: 117px;
	height: 41px;
	border: 1px solid #424243;
	border-left: 0px;
	background-color: #fcedba;
	text-transform: uppercase;
}

.salonFormBtnText {
	color: #424243;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.75px;
}

.salonPhone {
	white-space: nowrap;
}

.salonBusinessHours {
	white-space: nowrap;
}


.salonOptions {
	display: flex;
	align-items: center;
}

.salonOption {
	width: 32px;
	height: 32px;
	margin-right: 12px;
	display: none;

	&:last-child {
		margin-right: 0;
	}

	&[data-icon="kids"] {
		@include icon('../../assets/icon-option-kids.svg');
	}

	&[data-icon="coffee"] {
		@include icon('../../assets/icon-option-coffee.svg');
	}

	&[data-icon="ramp"] {
		@include icon('../../assets/icon-option-ramp.svg');
	}

	&[data-icon="parking"] {
		@include icon('../../assets/icon-option-parking.svg');
	}

	&[data-icon="wifi"] {
		@include icon('../../assets/icon-option-wifi.svg');
	}

	&[data-icon="brows"] {
		@include icon('../../assets/icon-option-brows.svg');
	}

	&.salonOptionActive {
		display: inline-block;
	}
}

@media screen and (max-width: 480px) {
	.salonContainer {
		width: 220px;
		font-size: 14px;

		.salonName {
			margin-bottom: 8px;
		}

		.salonAddress {
			margin-bottom: 8px;
		}

		.salonContacts {
			margin-bottom: 16px;
		}

		.salonOptions {
			margin-bottom: 16px;
		}

		.salonTextLinkContainer {
			padding-top: 0;
			flex-direction: column-reverse;
			align-items: flex-start;
		}

		.salonText {
			padding-top: 0;
			max-width: 220px;
			white-space: normal;
		}
	}
}
