.container {
	display: flex;
}

.textContainer {
	color: #414042;
	font-size: 33px;
	font-weight: 500;
	letter-spacing: 0.83px;
	margin-top: 15px;
	display: flex;
}

.menuContainer {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.insideMenu {
	display: flex;
	align-items: center;
}

.textContainerRight {
	color: #807f7f;
	font-size: 12px;
	font-weight: 400;
	box-sizing: border-box;
	letter-spacing: 1.2px;
	margin-right: 2px;
	margin-left: 2px;
	text-transform: uppercase;
	cursor: pointer;
	z-index: 2;
}

.cityFixArrow {
	display: flex;
	margin-right: 3px;
}

.textContainerRight:hover {
	color: #272727;
	letter-spacing: 1.2px;
	transition: color 500ms linear 0ms;
}

.textContainerRightMap {
	color: #272727;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1.2px;
	text-transform: uppercase;
}

.textContainerRightList {
	color: #272727;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1.2px;
	text-transform: uppercase;
}

.rightContainerSybol {
	color: #807f7f;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1.2px;
	margin-right: 4px;
	margin-left: 4px;
}

.rightContainerCity {
	color: #807f7f;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1.2px;
	margin-right: 2px;
	margin-left: 2px;
	text-transform: uppercase;
	display: flex;
	justify-content: flex-end;
}

.rightContainerSybolMobile {
	color: #807f7f;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1.2px;
	margin-right: 4px;
	margin-left: 4px;
	display: none;
}

@media screen and (max-width: 480px) {

	.cityFixArrow {
		display: none;
	}

	.rightContainerSybol {
		display: none;
	}

	.rightContainerSybolMobile {
		display: block;
	}

	.textContainerRight {
		font-size: 14px;
	}

}
