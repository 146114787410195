.container {
	display: flex;
	align-items: center;
	cursor: pointer;
}

$popupOffset: 26px;

.text {
	line-height: 14px;
	margin: auto 4px auto auto;
}

.cityNameContainer {
	position: relative;
}

.cityName {
	font-weight: 500;
	position: relative;
}

.box {
	position: relative;
}

@media screen and (max-width: 480px) {

	.mobileText {
		display: none;
	}
	.popup {
		transform: none;
		z-index: 102;
	}

	.popupBackDrop {
		z-index: 101;
	}

	.cityName {
		z-index: 100;
	}

	.arrow {
		z-index: 100;
	}
}

