.fieldTitle {
	font-size: 18px;
	line-height: 1.2em;
}

@media screen and (max-width: 480px) {
	.fieldTitle {
		font-size: 14px;
	}
}
