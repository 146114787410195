@import "../../theme/colors";

.container {
	display: flex;
	align-items: flex-end;
	font-size: 18px;
	line-height: 1.2em;

	.nameContainer {
		font-weight: 500;

		.amount {
			&:before {
				content: ' ';
			}
		}
	}

	.attributes {
		display: inline-flex;
		margin-left: auto;
	}

	.durationContainer {
		margin-left: auto;
		width: 92px;
		text-align: right;
		flex-shrink: 0;
		font-weight: 400;

		.durationUnits {
			&:before {
				content: ' ';
			}
		}
	}

	.priceBg {
		background-color: $color-yellow;
		display: inline-block;
		padding: 0 2px 0 4px;
		white-space: nowrap;
	}

	.priceContainer {
		width: 92px;
		text-align: right;
		margin-left: 6px;
		flex-shrink: 0;

		.price {
			font-weight: 500;
		}

		.currency {
			font-weight: 200;

			&:before {
				content: ' ';
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.container {
		font-size: 14px;
		align-items: flex-start;

		.attributes {
			flex-direction: column-reverse;
		}

		.nameContainer {
			font-weight: normal;
		}

		.priceContainer {
			.price {
				font-weight: normal;
			}
		}

		.durationContainer {
			font-size: 10px;
		}
	}
}
