@import "~@/theme/colors";

.container {
	padding-top: 24px;
}

.tabsContainer {
	margin: 0 0 16px;
}

.tabs {
	margin-bottom: 8px;
}

.tabContainer {
	display: flex;
	align-items: center;
	position: relative;

	&.selected {
		font-weight: 500;
	}

	&:not(:last-of-type) {

		margin-right: 32px;

		&:after {
			content: '|';
			font-weight: normal;
			margin: 0 16px;
			position: absolute;
			right: -32px;
		}
	}
}

.tab {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;
	white-space: nowrap;
	display: flex;

	sup {
		color: $color-red;
		font-size: 9px;
		font-weight: 700;
	}
}


.indicator {
	background-image: url('~@/assets/tabs-indicator-dark.svg');
}

.appointmentAction {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}


@media screen and (max-width: 480px) {
	.tabsContainer {
		margin: 0 -20px 16px;
	}
}
