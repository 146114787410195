.container {
		width: 100%;
		height: 100%;
		margin: 0 24px 16px;
}

.contentBlock {
		display: flex;
		flex-direction: column;
		margin-top: 15px;
}

.icon {
		width: 120px;
		margin: 0 auto;
		height: 120px;
}

.title {
		font-size: 24px;
		font-weight: 500;
		letter-spacing: 0.6px;
		margin-bottom: 8px;
		margin-top: 12px;
		white-space: pre-line;
}

.secondText {
		font-size: 18px;
		white-space: pre-line;
		line-height: 1.2em;
}

@media screen and (max-width: 480px) {

		.container {
				width: auto;
				height: auto;
				margin: auto;
		}

		.title {
				font-size: 21px;
		}

		.secondText {
				font-size: 14px;
		}

		.contentBlock {
				margin-left: 0;
				margin-top: 30px;
		}
}
