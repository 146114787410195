.blocks {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.phone {
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 0.6px;
	margin-bottom: 8px;
}

.title {
	font-size: 35px;
	text-align: center;
	font-weight: 500;
	width: 500px;
	margin: 0 auto 32px;
	line-height: 1.2em;
}

.blockTitle {
	display: inline-block;
	margin-bottom: 8px;
	border-bottom: 2px solid #eef4f7;
	font-size: 16px;
	line-height: 1.5em;
}

.info {
	color: #737373;
	font-size: 18px;
	line-height: 1.2em;
}

.comment {
	margin-top: 4px;
}

.block {
	width: 50%;
	display: flex;
	flex-grow: 1;
	margin-bottom: 32px;
	box-sizing: border-box;
	padding-right: 40px;
}

.icon {
	width: 60px;
	height: 60px;
	padding-right: 20px;
}

.blockIcon {
	height: 100%;
}

@media screen and (max-width: 480px) {

	.block {
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.blockContent {
		font-size: 12px;
		margin-left: 16px;
	}

	.title {
		font-size: 14px;
	}

	.phone {
		font-size: 16px;
	}

	.info {
		font-size: 12px;
	}

	.icon {
		padding: 0;
	}

	.blockContent:first-of-type {
		justify-content: flex-start;
	}
}

@media screen and (max-width: 330px) {

	.title {
		font-size: 21px;
		width: auto;
	}

	.block {
		padding: 5px;
		margin: 0;
	}
}
