@import "../../theme/colors";
@import "../../theme/borders";
@import "../../theme/iconMixin";

:global {
	.DayPicker {
		width: 100%;
		font-size: 16px;
		outline: none;
	}

	.DayPicker-wrapper {
		outline: none;
	}

	.DayPicker-Month {
		border-spacing: 10px;
		border-collapse: separate;
		width: 100%;
		margin: 1em 0 0;
	}

	.DayPicker-Day {
		z-index: 10;
		padding: 0.5em 0;

		&:focus {
			outline: none;
		}
	}

	.DayPicker-Caption {
		text-transform: uppercase;
		text-align: center;

		& > div {
			text-align: center;
		}
	}

	.DayPicker-Day--selected::before {
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		content: '';
		height: 32px;
		width: 32px;
		border-radius: 50%;
		z-index: -1;
		background: #ddebf3;
	}

	.DayPicker-Day {
		position: relative;
	}

	.DayPicker-Day:not(:last-of-type)::after {
		display: inline-block;
		position: absolute;
		right: -5px;
		top: 0;
		content: '';
		height: 36px;
		width: 1px;
		z-index: -1;
		background: #dce0e0 !important;
	}

	.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
		background: none;

		&:before {
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: '';
			height: 32px;
			width: 32px;
			border-radius: 50%;
			z-index: -1;
			background: #F0F8FF;
		}
	}

	.DayPicker-NavButton {
		top: 15px;
		margin: 0;
		outline: none;

		&--prev {
			left: calc(50% - 100px);
		}

		&--next {
			right: calc(50% - 100px);
		}
	}
}

.container {

}

.steps {
	padding: 24px 0;
	border-bottom: $light-border;
	margin-bottom: 32px;
}

.step {
	display: flex;
	margin-bottom: 16px;

	&[data-step="city"] {
		.stepIcon {
			@include icon('../../assets/step-1.svg');
		}
	}

	&[data-step="salon"] {
		.stepIcon {
			@include icon('../../assets/step-2.svg');
		}
	}

	&[data-step="service"] {
		.stepIcon {
			@include icon('../../assets/step-3.svg');
		}
	}

	&[data-step="date"] {
		.stepIcon {
			@include icon('../../assets/step-4.svg');
		}
	}

	&[data-step="time"] {
		.stepIcon {
			@include icon('../../assets/step-5.svg');
		}
	}

	.stepIcon {
		width: 50px;
		height: 50px;
		margin-right: 32px;
		flex: 0 0 50px;
	}

	.stepTitle {
		font-size: 18px;
		margin-bottom: 4px;
		letter-spacing: 0.025em;
	}

	.stepContent {
		font-weight: 500;
		font-size: 18px;
	}

	.stepInfo {
		padding-bottom: 16px;
		flex: 1;
		border-bottom: $light-border-thin;
	}

	&:last-of-type .stepInfo {
		border-bottom: none;
	}
}

.actions {
	display: flex;
	justify-content: center;
}


.cityPicker {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.cityName {
	margin-right: 4px;
}

.salonPicker {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.salonName {
	margin-right: 4px;
}

.salonTitle {
	white-space: nowrap;
}

.salonAddress {
	text-overflow: ellipsis;
}

.datePicker {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.date {
	margin-right: 4px;
	display: inline-block;
	min-width: 100px;
}

.pickerContainer {
	position: relative;
}

.dayPicker {
	background-color: #fff;
	width: 400px;
	flex: 0 0 400px;
}

.timeContainer {
	min-height: 200px;
	position: relative;
	font-weight: normal;
}

.timeEmpty {
	width: 100%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-medium-grey;
	font-style: italic;
}

.slotsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 16px;

	&:after {
		content: '';
		flex: auto;
	}

	.slot {
		letter-spacing: 0.025em;
		width: 50px;
		flex: 0 0 50px;
		cursor: pointer;
		text-align: center;
		padding: 4px 8px;
		user-select: none;

		&:not(.free) {
			color: $color-medium-grey;
			font-weight: 200;
		}

		&.free {
			font-weight: 500;
		}

		&.selected {
			background-color: $color-blue;
		}
	}
}

@supports (display: grid) {
	.slotsContainer {
		text-align: center;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
		grid-gap: 12px 24px;
		justify-items: center;

		.slot {

		}
	}
}

.clients {
	display: flex;
	flex-direction: column;
}

.client {
	width: 100%;
	margin-bottom: 24px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.summary {
	margin-bottom: 16px;
}

.clientHeader {
	font-weight: 400;
	color: #A9A9A9;
	display: flex;
	align-items: center;
	margin: 8px 0;

	&:after {
		content: '';
		border-bottom: 1px solid #A9A9A9;
		flex: 1;
	}

	span {
		padding-right: 8px;
		background-color: #fff;
	}
}

.clientActions {
	display: flex;
	align-items: center;

	.action {
		cursor: pointer;
		margin-right: 16px;
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400;

		.actionIcon {
			width: 27px;
			height: 27px;
			margin-right: 8px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&[data-action="add"] .actionIcon {
			@include icon('../../assets/icon-add-shadow.svg');
		}

		&[data-action="delete"] .actionIcon {
			@include icon('../../assets/icon-delete-shadow.svg');
		}
	}
}

.appointmentCityPicker {
	position: relative;
}

.commentContainer {
	width: 100%;
	box-sizing: border-box;
	border: $light-border-thin;
	position: relative;
	height: 100px;

	&.error .comment {
		color: $color-red;
	}

	&.error .commentLength {
		color: $color-red;
	}
}

.commentLength {
	position: absolute;
	bottom: 4px;
	right: 4px;
	color: $color-light-grey;
	font-size: 12px;
	font-weight: normal;
}

.comment {
	margin: 0;
	box-sizing: border-box;
	padding: 8px;
	resize: none;
	outline: none;
	font-size: 16px;
	border: none;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 480px) {

	.dayPicker {
		width: 100%;
		flex-basis: 100%;
	}

	.timeEmpty {
		text-align: center;
	}

	.popup {
		transform: none;
		z-index: 102;
	}

	.popupBackDrop {
		z-index: 101;
	}


	.step {

		.stepTitle {
			font-size: 14px;
		}

		.stepContent {
			font-size: 14px;
		}

		.clientActions {
			flex-direction: column;
			align-items: flex-start;
		}

		.action {
			font-size: 14px;
			margin-bottom: 16px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.date {
			min-width: 80px;
		}

		.stepIcon {
			display: none;
		}
	}
}
