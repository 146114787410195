.container {
	border-bottom: 2px solid #eef4f7;
	border-top: 2px solid #eef4f7;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 4px 0;
}

.content {
	width: 100%;
	background-color: #eef4f7;
	display: flex;
	text-align: center;
	flex-direction: column;
	padding: 10px 0;
}

.MainText {
	color: #807f7f;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 1.4px;
	text-transform: uppercase;
}

.MainTextBlock {
	width: 100%;
}

@media screen and (max-width: 480px) {
	.container {
		border: none;
		padding: 0;
	}

	.content {
		padding: 0 20px 30px;
	}

	.MainTextBlock {
		width: 220px;
		margin: 0 auto;
	}

	.MainText {
		width: 220px;
		font-size: 12px;
	}
}
