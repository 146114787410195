@import "~@/theme/colors.scss";

.container {
	width: 100%;
	flex: 0 0 auto;
	box-sizing: border-box;
	margin-top: 16px;
}

.seasons {

	display: flex;
	flex-direction: column;
	align-items: center;
}

.socials {
	display: flex;
	justify-content: center;
	margin-top: 16px;
}

.social {
	margin: 0 8px;
}

.instagramPhotos {
	display: flex;
	justify-content: center;
}

.copyright {
	text-align: center;
	margin: 16px 0;
}

.copyrightText {
	color: $color-medium-grey;
	font-size: 12px;
}

.menu {
	margin-top: 16px;
}

@media screen and (max-width: 480px) {

	.container {
		padding: 0;
	}

	.menu {
		height: auto;
	}

	.copyright {
		margin-bottom: 15px;
	}

	.copyrightText {
		width: 250px;
		display: block;
		text-align: center;
		margin: auto;
	}
}


