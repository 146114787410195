@import "~@/theme/circleBorderMixin";
@import "~@/theme/colors";

.container {
	/*padding: 0 30px;*/
}

.content {
	display: flex;
	flex-direction: row-reverse;
	border-bottom: 2px solid $color-blue;
	padding-bottom: 30px;
}

.newsList {
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
	padding: 15px 20px;
	border-bottom: 2px solid $color-blue;
}

.article {
	margin-bottom: 24px;
	line-height: 1.5em;
}

.appointmentLink {
	display: block;
	width: 200px;
}

.newsContent {
	display: flex;
	flex-direction: column;
	margin: 0 10px 0 10px;
	flex: 1;
}

.newsBox {
	flex: 0 0 350px;
	margin: 10px 15px 10px 15px;
	text-decoration: none;
}

.newsContentFooter {
	display: flex;
	flex-direction: column;
	flex: 0 0 350px;
}


.img {
	border-radius: 50%;
	width: 282px;
	height: 282px;
	overflow: hidden;
	@include circleBorderContent(282px, 10px, -10px);
}

.img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.imgFooter {
	border-radius: 50%;
	width: 200px;
	height: 200px;
	overflow: hidden;
}

.imgFooter img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.imgBox {
	display: flex;
	justify-content: center;
	margin-left: 40px;
	@include circleBorderContainer(314px, 282px, 0deg)
}

.imgBoxFooter {
	display: flex;
	justify-content: center;
}

.date {
	margin-top: 7px;
	color: #807f7f;
	font-size: 14px;
	font-weight: 400;
	font-style: italic;
	margin-bottom: 7px;
}

.dateFooter {
	margin-top: 7px;
	color: #807f7f;
	font-size: 13px;
	font-weight: 400;
	font-style: italic;
	margin-bottom: 7px;
}

.name {
	font-size: 28px;
	font-weight: 500;
	letter-spacing: 0.6px;
	margin-bottom: 15px;
	line-height: 1.2em;
}

.nameFooter {
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 0.6px;
	white-space: pre-line;
	margin-bottom: 15px;
}

.description {
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0.45px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.descriptionFooter {
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0.45px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.link {
	color: #0080ff;
	margin-top: 10px;
}

.linkFooter {
	color: #0080ff;
	margin-top: 10px;
}

.welcome {
	color: rgb(230, 0, 0);
	font-size: 16px;
	font-weight: 500;
	margin-top: 10px;
	letter-spacing: 0.45px;
	white-space: pre-line;
	padding-bottom: 10px;
}

@media screen and (max-width: 480px) {
	.content {
		flex-direction: column;
		align-items: center;
	}

	.name {
		font-size: 18px;
	}

	.newsContent .appointmentLink {
		margin: auto;
	}

	.newsContent {
		margin: 0 20px;
	}

	.imgBox {
		margin-left: 0;
		margin-bottom: 24px;
	}

	.img {

	}
}
