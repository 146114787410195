@import "../../theme/iconMixin";

.img {
	position: absolute;
	width: 120px;
	top: -20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 115;
	height: 21px;
}

.content {
	position: absolute;
	left: 50%;
	top: 40px;
	transform: translateX(-50%);
	z-index: 115;

	&:before {
		content: '';
		position: absolute;
		width: 120px;
		top: -20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 105;
		height: 21px;
		background-image: url('../../assets/popup-arrow.svg');
		background-repeat: no-repeat;
	}
}

.backDrop {
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 114;
}

.exit {
	position: absolute;
	right: 16px;
	top: 16px;
	cursor: pointer;
	z-index: 12;
	width: 20px;
	height: 20px;
	@include icon('../../assets/icon-close-thin.svg');
}

@media screen and (max-width: 480px) {

	.content {
		left: 0;
		top: 0;
		transform: translateX(0);
		position: fixed;
		width: 100%;
		max-width: 480px;
		min-width: 310px;

		&:before {
			display: none;
		}
	}

	.exit {
		top: 14px;
	}
}
