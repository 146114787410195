@import "~@/theme/colors.scss";

.root {
}

.title {
	font-size: 35px;
	text-align: center;
	font-weight: 500;
	width: 500px;
	margin: 0 auto 24px;
	line-height: 1.2em;
}

.subtitle {
	font-size: 18px;
	text-align: center;
}

.delimiter {
	display: flex;
	justify-content: center;
	font-size: 32px;
	font-weight: 500;
	position: relative;
	align-items: center;
	margin-bottom: 48px;
}

.delimiter:after,
.delimiter:before {
	content: '';
	display: block;
	border: 2px solid $color-blue;
	flex: 1;
}

.delimiterText {
	margin: 0 20px;
}

.bg {
	margin: 30px 0;

	img {
		width: 100%;
	}
}

.content {
	display: flex;
	border-bottom: 2px solid $color-blue;
}

.levels {
	margin-right: 60px;
	flex-direction: column;
}

.level {
	margin-bottom: 32px;
}

.additionalInfo {
	width: 420px;
	flex: 0 0 420px;
}

.infoBlock {
	display: flex;

	:not(:last-of-type) {
		margin-bottom: 32px;
	}
}

.infoIcon {
	width: 118px;
	flex-shrink: 0;
	margin-right: 32px;

	img {
		width: 100%;
	}
}

.infoText {
	font-size: 18px;
	margin-top: 22px;
}

.levelTitle {
	font-size: 32px;
	font-weight: 500;
	margin-bottom: 6px;
	display: inline-block;
	padding: 4px;
}

.level1 .levelTitle {
	background-color: #EFEFEF;
}

.level2 .levelTitle {
	background-color: #808080;
	color: #ffffff;
}

.level3 .levelTitle {
	background-color: #FFDA22;
}

.levelDescription {
	font-size: 18px;
	line-height: 1.2em;
}

.blueList {
	position: relative;
	box-sizing: border-box;
	width: 385px;
	transform: rotate(-3deg);
	margin-bottom: 48px;
}

.blueList:before,
.blueList:after {
	z-index: 1;
	position: absolute;
	content: "";
	bottom: 35px;
	left: 10px;
	width: 50%;
	top: 70%;
	background: #777;
	box-shadow: 0 35px 15px rgba(0, 0, 0, 0.3);
	transform: rotate(-8deg);
}

.blueList:after {
	transform: rotate(8deg);
	right: 10px;
	left: auto;
}

.blueListContent {
	padding: 24px 32px;
	background-color: $color-blue;
	z-index: 2;
	position: relative;
}

.blueListTitle {
	font-size: 32px;
	margin-bottom: 8px;
	font-weight: 500;
}

.action {
	padding: 48px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.buttonContainer {
	margin-bottom: 32px;
}

.joinText {
	transform: rotate(-3deg);
	font-size: 35px;
	color: #FF383A;
	margin-bottom: 38px;
	text-align: center;
	line-height: 1.3em;

	span {
		background-color: rgba(255, 56, 58, 0.1);
		padding: 0 4px;
	}
}

.arrow {
	width: 120px;
	height: 120px;
	position: absolute;
	display: block;
	background-image: url('~@/assets/club-arrow.svg');
	background-repeat: no-repeat;
	top: 110px;
	right: calc(50% - 300px);
}

@media screen and (max-width: 480px) {

	.root {
		padding: 0 20px;
	}

	.title {
		font-size: 21px;
		padding: 0 20px;
		width: auto;
	}

	.delimiter {
		font-size: 21px;
	}

	.subtitle {
		font-size: 14px;
	}

	.content {
		flex-direction: column;
	}

	.blueList {
		width: 100%;
		transform: none;
	}

	.additionalInfo {
		width: 100%;
		flex: 1 0 100%;
	}

	.joinText {
		font-size: 26px;
		transform: none;
	}

	.arrow {
		display: none;
	}

	.link {
		text-align: center;
	}

}
