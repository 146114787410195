@import "../../theme/borders";
@import "../../theme/iconMixin";

.container {
	font-size: 13px;

	&.expanded {
		.name {
			font-weight: 500;
		}

		.arrow {
			transform: rotate(90deg);
		}
	}
}

.header {
	display: flex;
	align-items: center;
	padding: 13px 0;
	cursor: pointer;
}

.name {
	margin-right: 4px;
}

.arrow {
	height: 10px;
	width: 10px;
	@include icon('../../assets/small-arrow.svg');
	display: block;
}

.children {
	border-top: $light-border-thin;
}
