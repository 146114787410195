@import "../../theme/colors";
@import "../../theme/circleBorderMixin";

.container {
	//margin: 0 30px 0 30px;
	position: relative;
	width: 100%;
}

.content {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 20px;
}

.newsBox {
	flex: 1;
	min-width: 290px;
	margin: 10px 15px 10px 15px;
	text-decoration: none;
	display: block;
	margin-bottom: 16px;

	&.first {
		.imgBox {
			&:before {
				background-image: url('../../assets/zavituha.svg');
			}
		}
	}
}

.newsContent {
	display: flex;
	flex-direction: column;
}


.img {
	position: relative;
	border-radius: 50%;
	width: 200px;
	height: 200px;
	overflow: hidden;
	@include circleBorderContent(196px, 10px, -10px);
}

.imgBox {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	@include circleBorderContainer(214px, 196px, 0deg);
}

.img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.date {
	margin-top: 7px;
	color: #807f7f;
	font-size: 13px;
	font-weight: 400;
	font-style: italic;
	margin-bottom: 7px;
}

.name {
	color: #414042;
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 0.6px;
	white-space: pre-line;
	margin-bottom: 15px;
}

.description {
	color: #414042;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0.45px;
	overflow: hidden;
	/*overflow: auto;*/
	text-overflow: ellipsis;
	/*white-space: pre-line;*/
}

.link {
	color: #0080ff;
	margin-top: 10px;
	display: inline;
	text-decoration: underline;
}


.pages {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 100%;
	flex-wrap: wrap;
}

.page {
	display: flex;
	color: $color-medium-grey;
	font-size: 14px;
	align-items: center;

	&:after {
		content: '|';
		display: block;
		font-weight: 400;
	}

	&:last-of-type::after {
		content: '';
	}
}

.pageLink {
	display: block;
	width: 20px;
	margin: 0 8px;
	text-align: center;
	text-decoration: none;

	&.active {
		color: $color-dark-grey;
		font-weight: 500;
	}
}

@media screen and (max-width: 480px) {
	.content {
		justify-content: center;

		.name {
			font-size: 18px;
		}

		.newsBox {
			min-width: auto;
			width: 100%;
			flex-basis: auto;
			box-sizing: border-box;
			margin: 0;
			margin-bottom: 24px;
		}
	}
	.pages {
		margin-bottom: 24px;
	}
}
