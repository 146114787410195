@import "../../theme/borders";

.container {
	position: relative;
	overflow: hidden;

	&:after {
		top: 0;
		left: 0;
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: $dark-border-thin;
		box-sizing: border-box;
	}

	.image {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
