@import "../../theme/colors";

.info {
	display: flex;
	align-items: center;

	.icon {
		width: 39px;
		height: 39px;
		margin-right: 16px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dateContainer {
		display: flex;
		font-weight: 500;
		white-space: nowrap;
	}

	.date {
		margin-right: 4px;
	}
}
