@import "~@/theme/colors";
@import "../../theme/borders";
@import "../../theme/iconMixin";
@import "../shadow";

$input-height: 50px;
$input-font-size: 18px;

.inputContainer {
	position: relative;
	cursor: pointer;
	outline: none;
}


.optionsContainer {
	display: none;
	width: 100%;
	padding: 0 12px;
	box-sizing: border-box;
}

.closeButton {
	display: block;
	@include icon('../../assets/icon-close-thin.svg');
	position: absolute;
	cursor: pointer;
	z-index: 2;
	width: 15px;
	height: 15px;
	top: 6px;
	right: 6px;
}

.option {
	position: relative;
	display: block;
	color: inherit;
	border-bottom: $dark-border;
	-webkit-tap-highlight-color: $color-yellow;
	line-height: $input-height - 2;
	height: $input-height - 2;
	font-size: $input-font-size;
	text-align: right;

	&:last-child {
		border-bottom: none;
	}
}

.inputContainer.focused .input {
	box-shadow: 0 0 0 1px $color-dark-grey;
}

.input {
	text-align: center;
	position: relative;
	z-index: 1;
	border: $dark-border;
	color: $color-dark-grey;
	width: 100%;
	background-color: transparent;
	transform: translateZ(0);
	box-sizing: border-box;


	&.isOpen {
		background-color: #fff;
		box-shadow: 0 0 0 1px $color-dark-grey;

		.optionsContainer {
			display: block;
		}

		.inputPlaceholder {
			border-bottom: $dark-border;

			&:after {
				transform: translateY(-50%) rotate(90deg);
			}
		}
	}
}

.inputPlaceholder {
	font-size: $input-font-size;
	line-height: $input-height - 2;
	height: $input-height - 2;
	width: 100%;
	padding: 0 12px;
	box-sizing: border-box;
	user-select: none;
	position: relative;
	padding-right: 24px;

	&:after {
		content: '';
		height: 23px;
		width: 23px;
		display: block;
		position: absolute;
		top: 50%;
		right: 4px;
		transform: translateY(-50%);
		@include icon('../../assets/arrow-thin.svg');
	}
}

@media screen and (max-width: 480px) {
	$input-height: 40px;
	$input-font-size: 14px;

	.option {
		border-bottom: $dark-border-thin;
		line-height: $input-height - 2;
		font-size: $input-font-size;
		height: $input-height - 2;
	}
	.input {
		border: $dark-border-thin;
	}
	.inputPlaceholder {
		font-size: $input-font-size;
		line-height: $input-height - 2;
		height: $input-height - 2;
	}
}
