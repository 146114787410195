.imgSettingsNavHeader {
    width: 21px;
    height: 22px;
    display: block;
}

.imgSettingsNavFooter {
    width: 34px;
    height: 34px;
    display: block;
}

.imgLink {
    /*width: 21px;*/
    /*height: 22px;*/
}
