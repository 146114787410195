@import "~@/theme/colors";
@import "~@/theme/borders";
@import "~@/theme/iconMixin";

.container {
	width: 100%;
	flex: 1 0 auto;
	box-sizing: border-box;
	overflow: hidden;
}

.loading {
	position: relative;
	height: 320px;
}

.contentContainer {
	border-bottom: 2px solid $color-blue;
}

.contentContainer:before {
	content: '';
	width: 125px;
	height: 2px;
	background-color: $color-medium-grey;
	margin: 0 auto;
	display: block;
}

.content {
	display: flex;
	flex-direction: column;
	margin: 30px auto;
	width: 600px;
	line-height: 1.5em;

	li {
		white-space: pre-wrap;
	}

	:global(.ql-size-large) {
		font-size: 33px;
		font-weight: 500;
		text-align: center;
		display: block;
		line-height: 1.2em;
	}

	:global(.ql-align-center) {
		text-align: center;
	}
}

.title {
	font-size: 35px;
	text-align: center;
	font-weight: 500;
	width: 500px;
	margin: 0 auto 24px;
	line-height: 1.2em;
}

.img {
	margin: 0 30px;
}

.imgGift {
	width: 400px !important;
	height: 400px !important;
}


.yandexMapContainer {
	width: 100%;
	display: flex;
	justify-content: center;

}

.yandexHeader {
	padding-left: 30px;
	padding-right: 30px;
}

.article {
	margin-bottom: 32px;
	line-height: 1.2em;
	font-size: 18px;
}

.moreContent {
	line-height: 1.5em;
	text-align: left;
}

.moreContent a {
	color: #0080ff;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: .35px;
}

.links {
	margin-bottom: 32px;
}

.certificates {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 70px;
}

.certificateContainer {
	width: 430px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 32px;
}

.certificateImageContainer {
	margin-bottom: 16px;
}

.certificateImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.order {

}

.cart {

}

.cartHeader {
	border-bottom: 1px solid $color-light-grey;
}

.cartContent {
	border-bottom: 1px solid $color-light-grey;
}

.promocodeContainer {
	display: flex;
	width: 100%;
	padding: 8px 16px;
	box-sizing: border-box;
	align-items: center;
}

.promocodeInput {
	margin-right: 10px;
}

.promocodeButton {
	width: 120px;
	margin-right: 10px;
}

.promocodeError {
	color: $color-red;
	height: 40px;
	line-height: 40px;
}

.promocodeSuccess {
	height: 40px;
	line-height: 40px;
}

.promocodeLoader {
	position: relative;
	height: 40px;
	width: 40px;
}

.cartSummary {
	padding: 16px;
	display: flex;
	align-items: center;
}

.cartTotal {
	font-weight: 500;
	font-size: 18px;
	margin-left: auto;
	margin-right: 16px;
	text-align: right;
}

.cartPayment {

}

.cartItemRemove {
	width: 24px;
	height: 24px;
	border: none;
	outline: none;
	cursor: pointer;
	@include icon('../../assets/icon-remove.svg');
}

.cartRow {
	display: flex;
	align-items: center;
	position: relative;
	max-width: 100%;
}

.cartCell {
	flex-grow: 1;
	flex-shrink: 0;
	padding: 8px 16px;
	box-sizing: border-box;
}

.cartCell[data-name="image"] {
	width: 128px;
	flex-grow: 0;
}

.cartCell[data-name="recipient"] {
	line-height: 1.5em;
}

.cartCell[data-name="amount"] {
	width: 160px;
	flex-grow: 0;
}

.cartCell[data-name="sum"] {
	width: 160px;
	flex-grow: 0;
	text-align: right;
}

.cartCell[data-name="actions"] {
	width: 216px;
	flex-grow: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.cartRecipient {
	display: flex;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.recipientChange {
	cursor: pointer;
	border: none;
	outline: none;
	margin-left: 8px;

	&:before {
		content: '';
		display: block;
		width: 24px;
		height: 24px;
		@include icon('../../assets/icon-edit.svg');
	}
}

.cartAmount {
	display: flex;
	align-items: center;
	font-size: 16px;

	.amount {
		width: 48px;
		height: 32px;
		line-height: 32px;
		box-sizing: border-box;
		text-align: center;
		border: $dark-border-thin;
		user-select: none;
	}

	.amountIncrease {
		margin-left: 8px;
		cursor: pointer;
		border: none;
		outline: none;

		&:before {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			@include icon('../../assets/icon-add.svg');
		}
	}

	.amountReduce {
		cursor: pointer;
		margin-right: 8px;
		border: none;
		outline: none;

		&:before {
			content: '';
			display: block;
			width: 24px;
			height: 24px;
			@include icon('../../assets/icon-delete.svg');
		}
	}
}

.orderEmpty {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 32px;
}

.orderEmptyTitle {
	font-size: 24px;
	margin-bottom: 16px;
}

.cartImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.fields {
}

.actions {
	padding: 16px 0;
	display: flex;
}

.paymentStatusPending {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 24px;
}

.paymentLoaderContainer {
	position: relative;
	height: 120px;
	width: 100%;
}

.paymentStatusSuccess {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 24px;
}

.paymentSuccessIconContainer {
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.paymentSuccessIcon {
	width: 48px;
	height: 48px;
	@include icon('../../assets/icon-success.svg')
}

.paymentStatusError {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 24px;
}

.paymentErrorIconContainer {
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.paymentErrorIcon {
	width: 48px;
	height: 48px;
	@include icon('../../assets/icon-error.svg')
}

.statusTextContainer {
	max-width: 320px;
	text-align: center;
	line-height: 1.5em;
	white-space: pre-wrap;
}

@media screen and (max-width: 480px) {

	.article {
		font-size: 14px;
	}

	.title {
		font-size: 21px;
		padding: 0 20px;
		width: auto;
	}

	.certificates {
		padding: 0;
	}

	.content {
		:global(.ql-size-large) {
			font-size: 21px;
		}
	}

	.certificateContainer {
		width: 100%;
		max-width: unset;
		padding: 32px 20px;
		border-bottom: $light-border;
		margin-bottom: 0;
	}

	.certificateContainer:first-of-type {
		padding-top: 0;
	}

	.certificateContainer:last-of-type {
		border-bottom: none;
	}

	.content {
		width: auto;
		margin: 30px;
	}

	.img {
		margin: 0 30px 0 30px;
		display: flex;
		justify-content: center;
	}

	.img img {
		width: 278px !important;
		height: 278px !important;
	}

	.imgGift {
		width: 278px !important;
		height: 278px !important;
	}

	.cartHeader {
		display: none;
	}

	.cartSummary {
		flex-wrap: wrap;
	}

	.cartTotal {
		margin-left: auto;
		margin-right: 0;
		width: 100%;
		margin-bottom: 24px;
	}

	.cartAddMore {
		margin: 16px auto 0;
		order: 2;
	}

	.cartPayment {
		margin: 0 auto;
	}

	.cartRow {
		flex-wrap: wrap;
		padding: 16px;
		border-bottom: $light-border-thin;
	}

	.cartCell {
		padding: 0;
	}

	.cartCell[data-name="image"] {
		width: 160px;
		margin-bottom: 8px;
	}

	.cartCell[data-name="recipient"] {
		width: calc(100% - 168px);
		align-self: flex-start;
		margin-left: 8px;
		margin-bottom: 8px;
		flex-shrink: 1;
	}

	.cartCell[data-name="recipient"] .cartRecipientInfo {
		max-width: calc(100% - 24px);
		overflow: hidden;
	}

	.cartCell[data-name="recipient"] .cartRecipientProp {
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.cartCell[data-name="recipient"] .cartRecipientInfo:before {
		content: 'Получатель:';
		margin-bottom: 4px;
		color: $color-light-grey;
	}

	.cartCell[data-name="recipient"] .cartRecipient {
		justify-content: space-between;
	}

	.recipientChange {
		align-self: flex-start;
	}

	.cartCell[data-name="amount"] {
		width: 160px;
		display: flex;
		justify-content: center;
	}

	.cartCell[data-name="sum"] {
		width: auto;
		flex-grow: 1;
		text-align: left;
		font-size: 18px;
		margin-left: 8px;
	}

	.cartCell[data-name="actions"] {
		width: auto;
	}

	.promocodeContainer {
		flex-wrap: wrap;
	}

	.promocodeButton {
		margin-right: 0;
	}

	.promocodeSuccess {
		margin-left: auto;
		margin-top: 8px;
		text-align: right;
	}

	.promocodeError {
		margin-left: auto;
		margin-top: 8px;
		text-align: right;
	}

	.promocodeLoader {
		margin-left: 8px;
	}
}
