.imgContainer {
	height: 90px;
	width: 145px;
	cursor: pointer;
	margin-bottom: 8px;
}

.container {
	width: 100%;
	justify-content: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

.subMenuContainer {
	display: flex;
	margin: 20px 0 20px 0;
	justify-content: center;
	height: 20px;
}

.contentBox {
	padding: 0 10px 0 0;
	cursor: pointer;
	white-space: nowrap;
}

.contentTextHover {
	border-bottom: 2px solid #cedde4;
	color: #272727 !important;
	/*font-weight: 500 !important;*/
}


.contentText {
	display: inline-block;
	color: #807f7f;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1.2px;
	text-transform: uppercase;
	transition: color 300ms linear;
	white-space: nowrap;
}

.contentBox::after {
	display: inline-block;
	padding-left: 10px;
	content: '|';
	color: #807f7f;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1.2px;
}

.contentBox:last-of-type::after {
	content: '';
}

.menuItem {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
}

.menuItemText {
	text-transform: uppercase;
	margin-top: 10px;
	color: #807f7f;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 1.2px;
}

.menuItemTextHover {
	color: #414042 !important;
}

.mainMenuBox {
	display: inline-flex;
	justify-content: center;
	position: relative;
	width: auto;
	flex-wrap: nowrap;
}

.menuBox {
	height: 120px;
	font-size: 12px;
	width: 145px;

	&[data-value="10"] {
		.menuTitle {
			width: 88px;
		}

		.imgContainer {
			width: 96px;
		}
	}

	.menuTitle {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		text-align: center;
	}

	&.active .menuTitle {
		font-weight: 500;
	}
}

.menuLime {
	width: 2800px;
	height: 50px;
	position: absolute;
	transition: all 200ms linear 0ms;
}

.lineBoxContainer {
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	overflow: hidden;
	position: relative;
	flex-shrink: 0;
}

.menuWrapper {
	position: relative;

	.arrows {
		position: absolute;
		transform: translate(0, -50%);
		width: 100%;
		top: 50%;
		display: flex;
		justify-content: space-between;
		z-index: 12;
		pointer-events: none;
		user-select: none;

		.arrowLeft {
			left: -28px;
		}

		.arrowRight {
			right: -28px;
		}

		.arrowLeft, .arrowRight {
			pointer-events: all;
			position: absolute;
			cursor: pointer;
		}
	}
}

.menuContainer {
	width: 100%;
	margin: 20px 0 20px 0;
	display: flex;
	box-sizing: border-box;
	flex: 0;
	overflow: hidden;
	position: relative;
}

.servicesPage {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.servicesContainer {
	display: flex;
	flex: 0 0 350px;
	//margin-right: 16px;
	margin: 0 auto;
}

.servicesContainer:last-of-type {
	//margin-right: 0;
}

.activeMenu {
	max-width: 400px;
	min-width: 300px;
}

.imgPlaceholder {
	width: 90px;
	height: 90px;
	margin-bottom: 8px;
	border: 1px solid #807f7f;
	border-radius: 50%;
}

@media screen and (max-width: 480px) {

	.menuContainer {
		flex-direction: column;
		padding: 0 20px;
	}

	.mainMenuBox {
		height: 64px;
	}

	.menuBox {
		box-sizing: border-box;
		width: 40px;
		height: 32px;
		margin: 0 4px;
		-webkit-tap-highlight-color: transparent;

		&[data-value="3"] {
			width: 56px;

			.imgContainer {
				width: 56px;
			}
		}

		.menuTitle {
			display: none;
			font-size: 10px;
			text-transform: uppercase;
			letter-spacing: 0.1em;
		}

		.imgContainer {
			width: 40px;
			height: 32px;
			transition: width .05s linear, height .05s linear, margin-top .05s linear;
			margin-bottom: 6px;
			margin-top: 4px;
		}

		.imgPlaceholder {
			width: 32px;
			height: 32px;
			margin-bottom: 6px;
			margin-top: 4px;
		}

		&.active {

			width: 56px;
			height: 72px;

			&[data-value="3"] {
				width: 80px;

				.imgContainer {
					width: 80px;
				}
			}

			.imgContainer {
				width: 56px;
				height: 40px;
				margin-top: 0;
			}

			.menuTitle {
				display: block;
			}

		}

		&.active .menuTitle {
			font-weight: 500;
		}
	}

	.menuWrapper {
		.arrows {
			display: none;
		}
	}

	.servicesPage {
		flex-direction: column;
	}

	.servicesContainer {
		margin-bottom: 48px;
		flex: auto;
		width: 100%;
	}

	.serviceInfo {
		width: 100%;
	}

	.subMenuContainer {
		flex-direction: column;
		align-items: center;
		margin: 0;
		height: auto;
	}

	.contentBox {
		margin-bottom: 8px;
		padding: 0;

		&:after {
			display: none;
		}
	}
}
