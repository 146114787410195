@import "../../theme/iconMixin";
@import "../../theme/colors";
@import "../../theme/borders";

.textarea {
	width: 100%;
	height: 200px;
	box-sizing: border-box;
	padding: 8px;
	resize: none;
	outline: none;
	font-size: 18px;
	border: $light-border-thin;
}

.previews {
	display: flex;
	align-items: center;
	margin: 24px 0;
	flex-wrap: wrap;
	min-height: 90px;
	position: relative;
	width: 100%;
	justify-content: center;
}

.preview {
	width: 90px;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 16px;
	position: relative;

	.removePhotoIcon {
		width: 24px;
		height: 24px;
		position: absolute;
		top: 0;
		right: 0;
		background-color: #ffffff;
		cursor: pointer;
		@include icon('../../assets/icon-close-thin.svg');
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}


.checkboxContainer {
	width: 100%;
	margin-bottom: 24px;
}

.visitInfo {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	padding: 2px 24px 2px 0;
	height: 100%;
	box-sizing: border-box;

	.salonInfo {
		padding-left: 16px;
		border-left: $dark-border-thin;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
		margin-left: 16px;
	}
}

.stars {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 12px;
	border-bottom: $dark-border-thin;

	&:last-of-type {
		border-bottom: none;
	}

	.starsTitle {
		width: 320px;
	}
}

@media screen and (max-width: 480px) {
	.textarea {
		font-size: 14px;
		height: 140px;
	}
	.stars {
		flex-direction: column;
		padding: 16px 0;
	}

	.starsTitle {
		width: 100%;
		text-align: center;
		margin-bottom: 8px;
	}

	.visitInfo {
		flex-wrap: wrap;
		padding-left: 0;
		padding-right: 0;

		.dateInfo {
			height: 14px;
			line-height: 14px;
		}

		.salonInfo {
			height: 14px;
			line-height: 14px;
			padding-left: 0;
			border-left: none;
			width: 100%;
			flex: 0 0 100%;
			margin-left: 0;
		}
	}
}
