@import "../theme/colors";

.shadow {
	background-color: transparent;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	transform: translate3d(4px, 4px, 0);

	&[data-color="grey"] {
		background-color: $color-dark-grey;
	}

	&[data-color="pink"] {
		background-color: $color-pink;
	}

	&[data-color="satin"] {
		background-color: $color-satin;
	}

	&[data-color="purple"] {
		background-color: $color-purple;
	}

	&[data-color="blue"] {
		background-color: $color-blue;
	}

	&[data-color="yellow"] {
		background-color: $color-yellow;
	}

	&[data-color="red"] {
		background-color: $color-red;
	}

}
@media screen and (max-width: 480px) {

}
