.headerBtn {
	width: 144px;
	height: 42px;
	background-color: #fcedba;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	font-family: inherit;
		-webkit-tap-highlight-color: transparent;
	color: #414042 !important;
}
