.changePassword {
	margin-top: 24px;
}

.changePasswordModal {
	width: 460px;
}

@media screen and (max-width: 480px) {
	.changePasswordModal {
		width: 100%;
	}
}
