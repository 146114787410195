@import "~@/theme/borders";
@import "~@/theme/iconMixin";

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.fields {
	padding: 32px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	.note {
		text-align: center;
		font-size: 18px;
		margin-bottom: 24px;
		letter-spacing: .25px;
		line-height: 21px;
		max-width: 400px;
	}
}

.field {
	width: 400px;
	margin-bottom: 24px;
}

.fieldFullWidth {
	width: 100%;
	margin-bottom: 24px;
}

.field:last-of-type {
	margin-bottom: 0;
}

.link {
	color: inherit;
	font-size: 14px;
}

.actions {
	padding-top: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-top: $light-border;
	width: 100%;

	.link {
		margin-top: 18px;
	}
}

.action {
	margin-bottom: 24px;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.resend {
	text-align: center;
	padding: 0 16px;
}

.personal {
	font-size: 12px;
	line-height: 1.2em;
}

.checkbox {
	display: none;

	& + .checkboxLabel:before {
		content: '';
		display: block;
		width: 32px;
		height: 32px;
		@include icon('~@/assets/checkbox-unchecked.svg');
		margin-right: 8px;
	}

	&:checked + .checkboxLabel:before {
		@include icon('~@/assets/checkbox-checked.svg');
	}
}

.checkboxLabel {
	display: flex;
	align-items: center;
	font-size: 18px;
	user-select: none;
	cursor: pointer;
}

@media screen and (max-width: 480px) {
	.field {
		width: 100%;
	}
	.link {
		font-size: 13px;
	}
	.fields {
		padding: 20px 0;

		.note {
			font-size: 14px;
			line-height: 1.2em;
		}
	}

	.actions {
		border-top: $light-border-thin;
	}

	.checkboxLabel {
		font-size: 14px;
	}
}
