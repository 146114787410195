@import "../../theme/colors";
@import "../../theme/borders";
@import "../../theme/iconMixin";

.container {
	outline: none;
	position: relative;
	background-color: #fff;
	box-sizing: border-box;
	padding: 20px;
	width: 400px;
}

.title {
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	margin-top: 16px;
}

.textarea {
	outline: none;
	width: 100%;
	border: $light-border-thin;
	padding: 6px;
	font-size: 15px;
	resize: none;
	box-sizing: border-box;
}

.cancelModalRadio {
	position: absolute;
	width: 0;
	height: 0;
	margin: 0;
	padding: 0;
	appearance: none;
	visibility: hidden;
}

.cancelModalRadioTitle {
	margin-left: 6px;
}

.cancelModalCheckmark {
	display: block;
	border-radius: 50%;
	border: $dark-border;
	background-color: #fff;
	box-sizing: border-box;
	flex-shrink: 0;
	padding: 2px;
	width: 15px;
	height: 15px;
	margin-right: 6px;

	.cancelModalRadio:checked + &:before {
		content: '';
		background-color: $color-dark-grey;
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
}

.fieldsContainer {
	padding: 20px 0;
}

.field {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 0;
	}
}

.rateField {
	@extend .field;
	display: block;
	border-bottom: $light-border-thin;
	margin-bottom: 12px;
	padding-bottom: 12px;

	.fieldsContainer &:last-of-type {
		border-bottom: none;
	}
}

.photoContainer {
	margin-bottom: 20px;

	.photoAdd {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 12px;

		&.disabled {
			opacity: .5;
		}
	}

	.photoAddIcon {
		@include icon('../../assets/photo-icon.svg');
		width: 23px;
		height: 23px;
		margin-right: 6px;
	}

	.photoAddTitle {
		margin-top: 4px;
	}

	.photoCounter {
		font-size: 14px;
		font-weight: 200;
		color: $color-medium-grey;
		text-align: center;
	}

	.photos {
		position: relative;
		display: flex;
		width: 100%;
		justify-content: center;
		margin-top: 12px;

		&.empty {
			display: none;
		}

		&.loading {
			height: 48px;
			display: flex;
		}
	}

	.photo {
		position: relative;
		width: 48px;
		height: 48px;
		margin-right: 16px;
		flex-shrink: 0;

		&:last-child {
			margin-right: 0;
		}

		&.selected {
			.photoDeleteIcon {
				display: block;
			}
		}

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.photoDeleteIcon {
		display: none;
		position: absolute;
		right: -10px;
		top: -10px;
		width: 20px;
		height: 20px;
		@include icon('../../assets/icon-remove.svg');
	}
}

.button {
	display: block;
	margin: 0 auto;
	width: 170px;
	box-sizing: border-box;
}

.fieldTitle {
	text-align: center;
	margin-bottom: 6px;
	font-size: 14px;
}

.starsContainer {
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 480px) {
	.container {
		width: 100%;
	}
}
