@import "../../theme/borders";

:global {
	.ReactModal__Overlay {
		opacity: 0;
		//transition: opacity linear .15s;

		&--after-open {
			opacity: 1;
		}
	}
}

.modal {
	outline: none;
	margin: auto;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	display: flex;
	overflow-y: auto;
	justify-content: center;
	align-items: center;
	will-change: opacity;
	background-color: rgba(255, 255, 255, 0.75);
	padding: 20px;
}

.content {
	background-color: #fff;
	box-shadow: 0 7px 5px rgba(0, 0, 0, 0.1);
	position: relative;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.contentContainer {
	padding: 24px 32px;
	box-sizing: border-box;
	width: 700px;
}

.title {
	line-height: 1.2em;
	font-weight: 500;
	text-align: center;
	border-bottom: $light-border;
	padding: 12px 0;
	font-size: 33px;
	white-space: pre-wrap;
}

.closeButton {
	display: block;
	background-image: url('../../assets/icon-close.svg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 18px 18px;
	position: absolute;
	cursor: pointer;
	z-index: 2;
	width: 24px;
	height: 24px;
	top: 6px;
	right: 6px;
}

.back {
	position: absolute;
	top: 6px;
	left: 6px;
	cursor: pointer;
}

@media screen and (max-width: 480px) {

	.closeButton {
		width: 21px;
		height: 21px;
		background-size: 18px 18px;
		top: 10px;
		right: 10px;
	}

	.back {
		top: 10px;
		left: 10px;
	}

	.title {
		font-size: 21px;
		border-bottom: $light-border-thin;
	}

	.overlay {
		align-items: flex-start;
		padding: 0;
	}
	.modal {
		margin: 0;
		width: 100%;

		&.center {
			margin: auto;
		}
	}
	.contentContainer {

		&:before {
			content: '';
			display: block;
			width: 115px;
			height: 30px;
			background-image: url('../../assets/logo/logo.svg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 115px 30px;
			margin: 6px auto;
		}

		width: 100%;
		padding: 0 20px 20px;
		box-sizing: border-box;
	}
}
