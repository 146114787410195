$color-dark-grey: #414042;
$color-light-grey: #B1B1B1;
$color-medium-grey: #6F6F6E;
$color-pink: #ffd9cd;
$color-yellow: #FDEEBB;
$color-blue: #DDEBF3;
$color-satin: #EBEBDC;
$color-red: #EF4136;
$color-purple: #E6E5ED;
$color-white: #FFFFFF;
