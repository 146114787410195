@import "../../theme/colors";

.title {
	font-size: 35px;
	text-align: center;
	font-weight: 500;
	width: 500px;
	margin: 0 auto 32px;
	line-height: 1.2em;
}

@media screen and (max-width: 480px) {
	.title {
		font-size: 21px;
		width: auto;
	}
}
