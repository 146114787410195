@import "../../theme/colors";
@import "../../theme/iconMixin";

.container {
	padding-left: 6px;

	&[data-type="1"] {
		.title {
			background-color: $color-yellow;
		}
	}

	&[data-type="2"] {
		.title {
			background-color: $color-blue;
		}
	}

	&[data-type="3"] {
		.title {
			background-color: $color-pink;
		}
	}

	&[data-type="4"] {
		.title {
			background-color: $color-satin;
		}
	}

	&[data-type="5"] {
		.title {
			background-color: $color-satin;
		}
	}

	&[data-type="6"] {
		.title {
			background-color: $color-satin;
		}
	}

	&[data-type="7"] {
		.title {
			background-color: $color-satin;
		}
	}

	&[data-type="8"] {
		.title {
			background-color: $color-purple;
		}
	}

	&[data-type="9"] {
		.title {
			background-color: $color-pink;
		}
	}


}

.title {
	white-space: normal;
	font-weight: 500;
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	padding: 0 6px;
	display: inline;
	width: auto;
	margin-left: -6px;
	margin-right: 6px;
}

.typeIcon {
	vertical-align: middle;
	display: inline-block;
	height: 1em;

	& > img {
		height: 100%;
		width: auto;
	}
}

.name {
	font-size: 24px;
	white-space: nowrap;
}

.mainInfo {
	margin: 8px 0;
	display: flex;
	align-items: baseline;
}

.linkToService {
	color: #0080ff;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.35px;
	margin-bottom: 10px;
}

.priceContainer {
	font-size: 35px;
	letter-spacing: 0.025em;
	margin-right: 4px;

	.price {
		font-weight: 500;
	}
}

.durationContainer {
	font-size: 16px;
	font-style: italic;
	letter-spacing: 0.025em;
	display: flex;
	align-items: center;
}

.durationIcon {
	display: block;
	width: 14px;
	height: 14px;
	@include icon('../../assets/icon-clock-small.svg');
	margin-right: 4px;
}


.descriptionContainer {
	margin-bottom: 32px;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0.45px;
	padding-top: 5px;
	line-height: 1.5em;
}


.altDescription {
	color: $color-medium-grey;
	margin-bottom: 8px;
}

@media screen and (max-width: 480px) {

	.container {
		padding: 0;
	}

	.name {
		font-size: 21px;
	}

	.action {
		display: flex;
		justify-content: center;
	}

	.priceContainer {
		font-size: 28px;
	}

	.durationContainer {
		font-size: 12px;
	}

	.descriptionContainer {
		font-size: 14px;
		ul {
			list-style: disc;
		}
	}

	.sliderBtn {
		display: flex;
		justify-content: center;
		text-decoration: none;
	}

}

