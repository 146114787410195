.container {
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		z-index: 101;
		line-height: 1.2em;
		box-sizing: border-box;
		cursor: default;
}

.mainLogo {
		width: 115px;
		height: 30px;
		margin: 6px auto;
}

.mainLogoContainer {
		width: 100%;
		height: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
}
