@import "../../theme/borders";
@import "../../theme/colors";
@import "../../theme/iconMixin";

.content {
	display: flex;
	box-sizing: border-box;
	margin-bottom: 32px;
}

.slider {
	flex: 1;
	margin-right: 24px;
	max-width: 750px;
}

.currentSlide {
	position: relative;
	width: 100%;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	iframe {
		width: 100%;
		object-fit: cover;
		height: 480px;
	}
}

.service {
	width: 380px;
	margin-top: 14px;
}


.description {

}

.infoImgBox {
	position: relative;
}

.descriptionImg {
	display: none;
	align-items: center;
	justify-content: center;
	padding: 2px;
	text-align: center;
	border: 1px solid black;
	font-size: 18px;
	letter-spacing: 0.45px;
	min-width: 230px;
	min-height: 30px;
	background-color: white;
	position: absolute;
	z-index: 10;
	bottom: 0;
	left: 0;
}

.altDescription {

}

.emptyImage {
	width: 100%;
	height: 100%;
	background-color: $color-light-grey;
}

.thumbnails {
	display: flex;
	margin: 8px 0;

	.thumb {
		position: relative;
		width: 102px;
		height: 78px;
		padding: 4px;
		box-sizing: border-box;
		flex: 0 0 102px;
		margin-right: 24px;
		cursor: pointer;
		border: 1px solid transparent;

		&.active {
			border: $dark-border-thin;
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

@supports (display: grid) {
	.thumbnails {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(102px, 102px));
		grid-gap: 12px 24px;
	}
	.thumb {
		margin-right: 0 !important;
	}
}

.name {
	white-space: normal;
	font-weight: 500;
	box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
	padding: 0 6px;
	font-size: 24px;
	display: inline;
	width: auto;
	margin-left: -6px;
	background-color: #EBEBDC;
}

.mainInfo {
	margin: 8px 0;
	display: flex;
	align-items: baseline;
}

.priceContainer {
	font-size: 35px;
	letter-spacing: 0.025em;
	margin-right: 4px;

	.price {
		font-weight: 500;
	}
}

.durationContainer {
	font-size: 16px;
	font-style: italic;
	letter-spacing: 0.025em;
	display: flex;
	align-items: center;
}

.durationIcon {
	display: block;
	width: 14px;
	height: 14px;
	@include icon('../../assets/icon-clock-small.svg');
	margin-right: 4px;
}


.descriptionContainer {
	margin-bottom: 32px;
	color: #414042;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0.45px;
	padding-top: 5px;
}

@media screen and (max-width: 480px) {

	.content {
		flex-direction: column;
		padding: 0 20px;
	}

	.currentSlide {
		height: auto;
		min-height: 240px;

		iframe {
			height: auto;
			min-height: 240px;
		}
	}

	.descriptionImg {
		z-index: auto;
	}

	.slider {
		margin: auto;
		width: 100%;
	}

	.thumbnails .thumb {
		flex: 0 0 70px;
		height: auto;
	}

	.service {
		width: 100%;
	}
}

