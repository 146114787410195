@import "../../theme/colors";

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	background-color: #fff;
	width: 382px;
	box-sizing: border-box;
	cursor: default;
}

.actions {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.visitsInfo {
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 16px;
}

.signOut {
	border: none;
	outline: none;
	user-select: none;
	letter-spacing: 1.65px;
	font-size: 12px;
	line-height: 20px;
	cursor: pointer;
	text-transform: uppercase;
	background: none;
}

.profile {
	margin-bottom: 8px;
}

.appointmentButton {
	margin-bottom: 8px;
	display: none;
}

.cashbackCodeContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 16px;
}

.cashbackCodeTitle {
	font-size: 18px;
	margin-bottom: 4px;
}

.cashbackCode {
	font-size: 32px;
	font-weight: 500;
	margin-bottom: 4px;
}

.cashbackCountdown {
	font-size: 12px;
}


@media screen and (max-width: 480px) {
	.container {
		width: 100%;
	}
	.visitsInfo {
		font-size: 14px;
	}
	.appointmentButton {
		display: block;
	}
}
