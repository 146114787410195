.container {
	max-width: 420px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	padding-top: 16px;
}

.label {
	margin-bottom: 8px;
	text-align: center;
}

.inputContainer {
	position: relative;
}

.prefix {
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
}

.suffix {
	position: absolute;
	right: 16px;
	top: 50%;
	transform: translateY(-50%);
}

.modal {
	width: 100%;

	&:before {
		display: none;
	}
}

@media screen and (max-width: 480px) {
	.container {
		max-width: 100%;
	}

	.inputContainer {
		width: 80%;
	}
}
