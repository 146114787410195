@import "~@/theme/circleBorderMixin";
@import "~@/theme/colors.scss";
@import "~@/theme/borders.scss";

.root {
	position: relative;
	min-height: 240px;
}

.stars {
	height: 150px;
	background-image: url('~@/assets/stars-bg.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	align-items: center;
	justify-content: center;
}

.iconContainer {
	padding-bottom: 32px;
}

.giftIconContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1.5em;
	z-index: 2;
}

.giftIcon:after {
	content: '';
	display: block;
	background-image: url('~@/assets/icon-gift.svg');
	z-index: 2;
	width: 105px;
	height: 105px;
	background-size: 82px 92px;
	background-repeat: no-repeat;
	background-position: center center;
}

.giftIcon:before {
	content: '';
	display: block;
	width: 105px;
	height: 105px;
	border-radius: 50%;
	background-color: $color-yellow;
	position: absolute;
	top: 6px;
	left: 2px;
	z-index: -1;
}

.circleBorderContent {
	position: relative;
	border-radius: 50%;
	@include circleBorderContent(109px, 4px, 0px);
}

.circleBorderContainer {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	@include circleBorderContainer(116px, 109px, -30deg);
}

.level {
	&:not(:last-of-type) {
		margin-bottom: 20px;
	}
}

.circle {
	box-sizing: border-box;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: $dark-border-thin;
	align-items: center;
	justify-content: center;
	display: flex;
	background-color: #fff;
	z-index: 2;

	&.active {
		background-color: $color-yellow;
	}
}

.checkmark {
	margin-right: 8px;

	&:before {
		content: '';
		display: block;
		width: 16px;
		height: 16px;
		background-image: url('~@/assets/checkmark-small.svg');
		background-repeat: no-repeat;
	}
}

.levelDescr {
	margin-left: 32px;
	border-bottom: $light-border;
	margin-bottom: 12px;
	padding-bottom: 12px;
	white-space: pre-wrap;
	line-height: 1.2em;
}

.issuedContainer {
	margin-left: 32px;
}

.levelTitle {
	display: flex;
	align-items: center;
	font-size: 20px;
	margin-bottom: 12px;
	font-weight: 500;
}
