.container {
	width: 100%;
	margin-bottom: 32px;
}

.menu {
	min-height: 40px;
	padding-top: 20px;
}

.logo {
	display: flex;
	justify-content: center;
}

.logoImage {
	height: 94px;
}

.link {
	height: 124px;
	display: flex;
	align-items: center;
}


@media screen and (max-width: 480px) {

	.logo {
		height: 50px;
	}

	.link {
		height: auto;
	}

	.container {
		margin: 0 0 12px;
	}

	.menu {
		display: none;
		z-index: 121;
	}

	.logoImage {
		height: 50px;
	}
}
