.container {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px !important;
	box-sizing: border-box;
}

.title {
	font-size: 35px;
	text-align: center;
	font-weight: 500;
	width: 500px;
	margin: 0 auto 32px;
	line-height: 1.2em;
}

.blockText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	line-height: 1.2em;
	font-size: 18px;
}

.blockTitle {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 24px;
	line-height: 1.2em;
}

.blockContent {
	line-height: 1.2em;
}

.block {
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
	box-sizing: border-box;
}

.block {
	&[data-index='1'], &[data-index='3'], &[data-index='5'], &[data-index='7'] {
		flex-direction: row-reverse;

		.blockImage {
			margin-right: 120px;
		}

		.smallImage {
			right: 0;
			left: auto;
			transform: translateX(50%) translateY(-50%);
		}
	}

	&[data-index='0'], &[data-index='4'], &[data-index='6'] {
		.blockImage {
			margin-left: 120px;
		}

		.smallImage {
			transform: translateX(-50%) translateY(-50%);
		}
	}

	&[data-index='2'] {
		background-color: #eef4f7;
		padding: 0 20px 30px;

		.blockImage {
			overflow: hidden;
			border-radius: 50%;
			border: 20px solid white;
			width: 450px;
			height: 450px;
			margin-top: -80px;
			margin-left: 40px;
			flex-shrink: 0;
		}

		.blockImagesContainer {
			overflow: hidden;
			border-radius: 50%;
			border: 2px solid black;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.mainImage {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.smallImage {
			display: none;
		}
	}

	&[data-index='6'] {
		margin-bottom: 0 !important;
	}
}

.smallImage {
	position: absolute;
	border-radius: 50%;
	border: 12px solid white;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	overflow: hidden;
}

.blockImagesContainer {
	position: relative;
}

@media screen and (max-width: 480px) {

	.title {
		font-size: 21px;
		width: auto;
	}

	.container {
		padding: 0 20px;
	}

	.smallImage {
		width: 100px !important;
		height: 100px !important;
		border: 8px solid white;
		left: 50%;
		transform: translateX(-50%);
	}

	.block {
		align-items: center !important;
		flex-direction: column-reverse !important;

		&[data-index='1'], &[data-index='3'], &[data-index='5'], &[data-index='7'] {
			.blockImage {
				margin-right: 0;
				margin-bottom: 60px;
			}

			.smallImage {
				margin-right: 0;
				left: 50%;
				top: auto;
				bottom: 0;
				transform: translateX(-50%) translateY(50%);
			}
		}

		&[data-index='0'], &[data-index='4'], &[data-index='6'] {

			.blockImage {
				margin-left: 0;
				margin-bottom: 60px;
			}

			.smallImage {
				margin-left: 0;
				left: 50%;
				top: auto;
				bottom: 0;
				transform: translateX(-50%) translateY(50%);
			}

		}

		&[data-index='2'] {
			margin-top: 125px;
			margin-left: -20px;
			margin-right: -20px;

			.blockImage {
				width: 250px;
				height: 250px;
				border: 12px solid white;
				margin: -125px 0 10px;
			}
		}

	}

	.block img {
		width: 100%;
		object-fit: cover;
		height: 100%;
	}

	.blockImage {
		width: 100%;
	}

	.blockText {
		line-height: 1.5em;
		text-align: left;
		font-size: 12px;
	}

	.blockTitle {
		font-size: 16px;
	}

	.blockContent {
		line-height: 1.5em;
	}

}
