@import "../../theme/colors";

.userInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 24px;
	text-align: center;

	.avatar {
		width: 52px;
		height: 52px;
		margin-bottom: 8px;
	}

	.name {
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 4px;
	}

	.email {
		font-size: 12px;
		letter-spacing: 1px;
		color: $color-medium-grey;
		text-transform: uppercase;
	}
}

@media screen and (max-width: 480px) {
	.userInfo {

		.avatar {
			width: 70px;
			height: 70px;
		}

		.name {
			font-size: 21px;
		}

		.email {
			font-size: 10px;
		}
	}
}
