.container {
    width: 100%;
    height: 100%;
    display: flex;
}

@media screen and (max-width: 480px) {

	.container {
		flex-wrap: wrap;

	}
}
