@import "../../theme/borders";
@import "../../theme/iconMixin";

.client {

}


.header {
	display: flex;
	justify-content: center;
	font-size: 16px;
	margin: 12px 0;
	text-transform: uppercase;

	&:before,
	&:after {
		content: '';
		display: block;
		border-bottom: $light-border-thin;
		flex: 1;
		transform: translateY(-50%);
	}

	&:before {
		margin-right: 8px;
	}

	&:after {
		margin-left: 8px;
	}
}

.services {
	padding: 12px 0;
}

.container {
	background-color: $color-satin;

	& .item:first-of-type {
		padding-top: 0;
		margin-top: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	& .item:last-of-type {
		padding-bottom: 0;
		margin-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.servicesContainer {
	border-bottom: $light-border-thin;
}

.service {
	margin-bottom: 12px;
	position: relative;

	&:last-of-type {
		margin-bottom: 0;
	}

	.iconDelete {
		position: absolute;
		right: -4px;
		top: 50%;
		width: 16px;
		height: 16px;
		transform: translate(100%, -50%);
		@include icon('../../assets/icon-delete.svg');
		cursor: pointer;
	}
}

.item {
	background-color: #fff;
	border-radius: 12px;
}

.totals {
	display: flex;
	align-items: flex-end;
	padding: 12px 0;
	font-size: 18px;
}

.totalsTitle {
	font-weight: 500;
	flex: 1;
}


.totalsAttributes {
	display: inline-flex;
	margin-left: auto;
}

.totalsDuration {
	text-align: right;
	flex-shrink: 0;
	font-weight: 400;
}

.totalsPrice {
	text-align: right;
	font-weight: 500;
	margin-left: 6px;
	width: 92px;
	flex-shrink: 0;

	& .currency {
		font-weight: 200;

		&:before {
			content: ' ';
		}
	}
}

.note {
	line-height: 1.2em;
	font-size: 11px;
	font-weight: 200;
	padding: 0 0 12px;
	color: $color-medium-grey;
	border-bottom: $light-border-thin;
}

.info {
	padding: 12px 0;
	border-bottom: $light-border-thin;
}

.dateInfo {
	margin-bottom: 12px;
}

.dateInfoIcon {
	@include icon('../../assets/icon-calendar-satin.svg');
	width: 40px;
	height: 40px;
}

.salonInfo {

}

.salonInfoIcon {
	@include icon('../../assets/icon-placemark-satin.svg');
	width: 40px;
	height: 40px;
}

@media screen and (max-width: 480px) {
	.iconDelete {
		display: none;
	}
	.totals {
		font-size: 14px;
		align-items: flex-start;

		.totalsAttributes {
			flex-direction: column-reverse;
		}

		.totalsDuration {
			font-size: 10px;
		}
	}
}
