@import "~@/theme/iconMixin";

.container {
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: column;
	z-index: 100;
	position: sticky;
	top: 0;
	left: 0;
	background-color: white;
	padding: 0;
	box-sizing: border-box;
	justify-content: center;
}

.main {
	display: flex;
	align-items: center;
	padding: 0 30px;
	width: 100%;
	box-sizing: border-box;
}

.socialIcons {
	display: flex;
	align-items: center;
	margin-right: 12px;
}

.socialIcon {
	margin-right: 7px;
}

.account {
	margin-left: auto;
}

.appointmentButton {
	margin-left: 10px;
}

.currentCity {
	margin-right: 10px;
}

.mobileMenuContainer {
	display: none;
	margin-left: 16px;
}

.appointmentButtonMobile {
	display: none;
}

:global .scrolled :local .container {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .24);
}

@media screen and (max-width: 480px) {

	.appointmentButtonMobile {
		display: block;
		width: 100%;
		flex-shrink: 0;
	}

	.container {
		display: flex;
		align-items: center;
		height: auto;
		transition: box-shadow linear .15s;
	}

	.main {
		padding: 0 20px;
		height: 48px;
	}

	.mobileMenuContainer {
		display: block;
	}

	.appointmentButton {
		display: none;
	}

	.mobileMenuIcon {
		display: block;
		width: 24px;
		height: 24px;
		@include icon('../../assets/icon-burger.svg');
	}

	.socialIcons {
		display: none;
	}

	.burgerMobile {
		z-index: 120;
	}

}
