@import "../../theme/borders";
@import "../../theme/colors";

.button {
	display: inline-block;
	width: 200px;
	height: 40px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	background-color: #fff;
	color: inherit;
	letter-spacing: 1.25px;
	outline: none;
	cursor: pointer;
	transition: opacity linear .15s;
	user-select: none;
	position: relative;
	z-index: 1;
	border: none;

	.content {
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border: $dark-border-thin;
		box-sizing: border-box;
	}

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		transform: translate(5px, 6px);
		top: 0;
		left: 0;
		z-index: 0;
		background-color: transparent;
	}

	&[data-shadow="grey"]:before {
		background-color: $color-dark-grey;
	}

	&[data-shadow="pink"]:before {
		background-color: $color-pink;
	}

	&[data-shadow="satin"]:before {
		background-color: $color-satin;
	}

	&[data-shadow="purple"]:before {
		background-color: $color-purple;
	}

	&[data-shadow="blue"]:before {
		background-color: $color-blue;
	}

	&[data-shadow="yellow"]:before {
		background-color: $color-yellow;
	}

	&[data-shadow="red"]:before {
		background-color: $color-red;
	}
}

.button[disabled] {
	opacity: .5;
}
