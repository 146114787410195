
.appointmentNotFoundModal {
	width: 400px;
}

.appointmentNotFoundModalContent {
	padding: 24px 0;
	line-height: 1.2em;
	width: 100%;
	text-align: center;
}

.appointmentNotFoundActions {
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.appointmentNotFoundAction {
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 0;
	}
}

.appointmentSubmittingModal {
	width: 400px;
	height: 240px;
}
