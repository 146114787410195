@import "../../theme/borders";

.note {
	font-size: 12px;
	font-weight: 200;
	margin-bottom: 16px;
}

.notification {
	display: inline-flex;
	align-items: center;
	margin-bottom: 16px;
	gap: 8px;
}

.info {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	border-bottom: $light-border;
	border-top: $light-border;
	padding: 2px 0;
	height: 40px;

	.salonInfo {
		padding-left: 16px;
		border-left: $light-border;
	}

	margin-bottom: 16px;
}

.actions {
	display: flex;
	justify-content: center;
}

.clientHeader {
	margin-top: 16px;
	color: #A9A9A9;
}

.commentTitle {
	margin-bottom: 8px;
	font-weight: 500;
}

.comment {
	margin-bottom: 24px;
	white-space: pre-wrap;
}

@media screen and (max-width: 480px) {
	.info {
		flex-direction: column;
		align-items: center;
		font-size: 14px;
		height: auto;
		padding: 0;
		border-bottom: $light-border-thin;
		border-top: $light-border-thin;

		.dateInfo {
			padding: 12px 0;
			border-bottom: $light-border-thin;
			width: 100%;
			text-align: center;
			justify-content: center;
		}

		.salonInfo {
			padding-left: 0;
			border-left: none;
			text-align: center;
			padding: 12px 0;

			span, a {
				white-space: nowrap;
			}
		}
	}
}
